import React from "react";
import cls from "./style.module.scss";
import { TablePagination } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const EnhancedTablePagination = ({
	count,
	rowsPerPage,
	page,
	onPageChange,
	onRowsPerPageChange,
	className,
	rowsPerPageOptions,
	ActionsComponent,
}) => {
	const { t } = useTranslation();
	return (
		<TablePagination
			ActionsComponent={ActionsComponent}
			rowsPerPageOptions={
				rowsPerPageOptions
					? rowsPerPageOptions
					: [5, 10, 25, { label: "Все", value: count }]
			}
			component='div'
			count={count}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={onPageChange}
			onRowsPerPageChange={onRowsPerPageChange}
			labelRowsPerPage={t("cтрок")}
			labelDisplayedRows={({ from, to, count }) => {
				return `${from} - ${to} ${t("из")} ${count}`;
			}}
			className={`${cls.equipment_and_services_pagination} ${
				className ? className : ""
			}`}
		/>
	);
};

export default EnhancedTablePagination;
