import types from '../Types';

const historyOperation = (values, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.MY_HISTORY_OPERATION });
    return api({
      method: 'POST',
      url: `/api/cabinet/my/history/operations`
    }).then(
      (data) => {
        dispatch({type:types.MY_HISTORY_OPERATION_SUCCESS,myHistory:data.data});
        callback && callback();
      },
      ({response}) => {
        dispatch({type:types.MY_HISTORY_OPERATION_ERROR,error:response});
        callback && callback(response);
      }
    )
  };
}

const historyOperationTop = (values, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.MY_HISTORY_OPERATION });
    return api({
      method: 'POST',
      url: `/api/cabinet/my/history/operations/top`
    }).then(
      (data) => {
        dispatch({type:types.MY_HISTORY_OPERATION_SUCCESS,myHistory:data.data});
        callback && callback();
      },
      ({response}) => {
        dispatch({type:types.MY_HISTORY_OPERATION_ERROR,error:response});
        callback && callback(response);
      }
    )
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  historyOperation,
  historyOperationTop
};
