import React from "react";
import {
	TableRow,
	TableCell,
	Select,
	MenuItem,
	Tooltip,
} from "@material-ui/core";
import cls from "./tableRowDesktop.module.scss";
import CheckboxStyle from "../../../CheckboxStyle";
import statusIconGenerator from "../../../../helpers/statusIconGenerator";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EmailIcon from "@material-ui/icons/Email";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TableRowDesktop = ({
	paddingCheck,
	checkboxnone,
	cursor,
	handleClickName,
	checkbox,
	headCells,
	handleClick,
	handleClickCheckBox,
	row,
	isItemSelected,
	index,
	style,
	className,
	tariff,
	handleTariff,
	rowIndex,
	unbind,
	email,
	emailnone,
	handleClickEmail,
	batchList,
}) => {
	const { t } = useTranslation();

	return (
		<TableRow
			style={style}
			onClick={(event) => handleClick(event, row)}
			tabIndex={-1}
			key={index}
			className={`${cls.desktop_version_table} ${
				unbind ? cls.desktop_version_table_unbind : ""
			} ${cursor ? cls.desktop_version_table_cursor : ""} ${
				isItemSelected ? cls.desktop_version_table_selected : ""
			}`}
		>
			{email && (
				<TableCell
					className={cls.desktop_version_table_email}
					padding='none'
					size='small'
					style={paddingCheck && { textAlign: "center" }}
				>
					<Tooltip
						title={
							batchList
								? t("Отправить на email контрольную ленту")
								: t("Отправить на email копию чека")
						}
						aria-label='email'
					>
						<EmailIcon
							onClick={handleClickEmail}
							style={{ verticalAlign: "middle" }}
						/>
					</Tooltip>
				</TableCell>
			)}
			{emailnone && <TableCell padding='none' size='small'></TableCell>}

			{checkbox && (
				<TableCell
					padding='checkbox'
					style={paddingCheck && { textAlign: "center" }}
				>
					<CheckboxStyle
						// style={paddingCheck && { padding: 2 }}
						onChange={(event) => handleClickCheckBox(event, row)}
						checked={isItemSelected}
					/>
				</TableCell>
			)}
			{checkboxnone && (
				<TableCell padding='checkbox'>
					<div
						style={{ height: 40, display: "flex", justifyContent: "center" }}
					>
						{statusIconGenerator(row.servStatus, "icon", t)}
					</div>
				</TableCell>
			)}

			{headCells.map((el, elIndex) => {
				let rowValue = row[el.id];

				if (el.id === "bnk" || el.id === "cati") {
					rowValue = (
						<div
							style={{ fontFamily: "inherit" }}
							dangerouslySetInnerHTML={{ __html: rowValue }}
						/>
					);
				}

				if (el.icon) {
					rowValue = statusIconGenerator(row[el.id], null, t);
				}
				if (el.doubleIcon) {
					rowValue = statusIconGenerator(row[el.id], "doubleIcon", t);
				}
				if (el.bool) {
					rowValue === 0 ? (rowValue = t("нет")) : (rowValue = t("да"));
				}
				if (el.orderNum) {
					if (Boolean(row.operType)) {
						rowValue = (
							<Link
								className={cls.link_style}
								to={`/orders?orderNum=${row.orderNum}`}
							>
								{t("Оформлен заказ №")}
								{row.orderNum}
							</Link>
						);
					} else {
						rowValue = t("Пополнение лицевого счёта");
					}
				}
				if (el.iconImg) {
					if (Boolean(row.operType)) {
						rowValue = (
							<div className={cls.block_icon_img}>
								<RemoveCircleOutlineIcon className={cls.minus_icon} />
								{rowValue}
							</div>
						);
					} else {
						rowValue = (
							<div className={cls.block_icon_img}>
								<AddCircleOutlineIcon className={cls.plus_icon} />
								{rowValue}
							</div>
						);
					}
				}
				if (el.prefix) {
					rowValue = `${rowValue.toFixed(2)} ${el.prefix}`;
				}
				if (el.combobox) {
					rowValue = (
						<Select
							key={rowIndex}
							value={tariff}
							onChange={handleTariff}
							className={cls.style_select}
						>
							{el.comboboxData.map((value) => {
								return (
									<MenuItem key={value.tariffId} value={value.tariffId}>
										{value.tariffName}
									</MenuItem>
								);
							})}
						</Select>
					);
				}

				return (
					<TableCell
						onClick={el.id === "name" ? handleClickName : null}
						className={className}
						key={elIndex}
						align={el.align}
					>
						{rowValue ? rowValue : rowValue !== 0 ? "-" : 0}
					</TableCell>
				);
			})}
		</TableRow>
	);
};

export default TableRowDesktop;
