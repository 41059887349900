import React from "react";
import cls from "./modalContent.module.scss";
import TextField from "@material-ui/core/TextField";

const ModalContentAdd = ({
	header,
	login,
	fio,
	onChangeLogin,
	onChangeFio,
	disabledLogin,
	errorLogin,
}) => {
	return (
		<div className={cls.modal_content_wrapper}>
			<div className={cls.modal_content_head}>
				<h2>{header}</h2>
			</div>
			<div className={cls.modal_content_main}>
				<TextField
					id='name'
					label='Логин'
					required
					autoFocus
					onChange={onChangeLogin}
					value={login}
					disabled={disabledLogin}
					error={errorLogin}
					helperText={
						errorLogin && "Логин не может совпадать с основным пользователем"
					}
				/>
				<TextField
					id='name'
					label='ФИО'
					required
					autoFocus
					onChange={onChangeFio}
					value={fio}
				/>
			</div>
		</div>
	);
};

export default ModalContentAdd;
