import React, { useEffect, useState, Fragment } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
} from "@material-ui/core";
import SortHelper from "../../../../helpers/sortHelper";
import cls from "./equipmentAndServices.module.scss";
import EnhancedTableHead from "./components/EnhancedTableHead";
import EnhancedTableToolbar from "./components/EnhancedTableToolbar";
import handleClickRow from "../../../../helpers/handleClickRow";
import HandlerRowDesktopAndMobile from "../../../../components/HandlerRowDesktopAndMobile";
import Loading from "../../../../components/Loading";
import ButtonStyle from "../../../../components/ButtonStyle";
import ModalFilter from "../../../../components/ModalFilter";
// import EnhancedTablePagination from '../../../../components/EnhancedTablePagination';
import { useHandleRequestSort } from "../../../../helpers/handleRequestSort";
// import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
// import EquipmentSelector from '../../../../redux/Selectors/Equipment';
import { useTranslation } from "react-i18next";
import ItemTableList from "./components/ItemTableList";
import CollapseButton from "../../../../components/CollapseButton";
import Types from "../../../../redux/Types";

const EquipmentAndServices = ({
	prepareOrderPay,
	resetTable,
	loading,
	role,
}) => {
	const { t } = useTranslation();
	const rows = useSelector((state) => state.services.preOrder);
	const headCells = [
		{
			id: "equType",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Устройство"),
			bool: false,
			mobileLine: false,
		},
		{
			id: "sbName",
			numeric: true,
			disablePadding: false,
			align: "left",
			label: t("Комплект ПО"),
			bool: false,
			mobileLine: true,
		},
	];
	const { preOrderLength } = useSelector((state) => state.services);
	// const history = useHistory();
	const dispatch = useDispatch();
	const [{ order, orderBy }, handleRequestSort] = useHandleRequestSort({
		order: "asc",
		orderBy: "equType",
	});

	const role1 = role === 1;

	// const handleChangePage = (event, newPage) => {
	//     setPage(newPage);
	// };

	// const handleChangeRowsPerPage = event => {
	//     setRowsPerPage(parseInt(event.target.value, 10));
	//     setPage(0);
	// };

	const emptyRows = 5 - Math.min(5, rows.length - 0 * 5);

	const [filterOpen, setFilterOpen] = useState(false);
	const [filterActive, setFilterActive] = useState({});

	const handleChangeFilterRows = (array, params) => {
		return array.filter((el) => {
			if (Object.keys(params).length) {
				let status = [];
				Object.keys(params).forEach((key) => {
					if (el[key] === params[key]) {
						status.push(true);
					} else {
						let statusEl = false;
						if (key === "servStatusAll" && Array.isArray(params[key])) {
							params[key].forEach((parEl) => {
								if (Boolean(el[key].find((elEl) => elEl === parEl))) {
									statusEl = true;
								}
							});
						}
						statusEl ? status.push(true) : status.push(false);
					}
				});
				let finishSearch = false;
				if (status.find((bool) => bool === false) === undefined) {
					finishSearch = true;
				}
				return finishSearch;
			}
			return true;
		});
	};

	const [newRows, setNewRows] = useState(
		handleChangeFilterRows(rows, filterActive)
	);
	const [selected, setSelected] = useState([]);
	// const [checkSelect, setCheckSelect] = useState(false);
	useEffect(() => {
		handleClickRow.handleSelectAllRowsCollapse(selected, newRows, setSelected);
		return () => {
			dispatch({
				type: Types.PRE_ORDER_REQUEST_SUCCESS,
				preOrder: [],
				preOrderLength: 0,
				preOrderParams: {},
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		resetTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);
	useEffect(() => {
		setNewRows(handleChangeFilterRows(rows, filterActive));
	}, [filterActive, rows]);
	const [collapse, setCollapse] = useState([]);
	// const [click, setclick] = useState(false);
	return (
		<>
			<Paper className={cls.equipment_and_services_paper_wrapper}>
				<EnhancedTableToolbar
					filterActive={filterActive}
					setFilterActive={setFilterActive}
					setFilterOpen={() => setFilterOpen(!filterOpen)}
					numSelected={selected.length}
				/>
				<TableContainer
					style={{ overflowX: "inherit" }}
					className={cls.container_wrapper}
				>
					<Loading load={loading} />
					<Table
						className={`${cls.equipment_and_services_table_min} ${cls.equipment_and_services_table_container}`}
						aria-labelledby='tableTitle'
						size='medium'
						aria-label='enhanced table'
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={() => {
								// setCheckSelect(true);
								handleClickRow.handleSelectAllRowsCollapse(
									selected,
									newRows,
									setSelected
								);
							}}
							onRequestSort={handleRequestSort}
							rowCount={preOrderLength}
							headCells={headCells}
							sort
							checkbox
						/>
						<ModalFilter
							setFilterOpen={() => setFilterOpen(false)}
							filterOpen={filterOpen}
							setFilterActive={setFilterActive}
							headCells={headCells}
							rows={rows}
							filterActive={filterActive}
							filterRows={newRows}
						/>
						<TableBody>
							{SortHelper(newRows, order, orderBy)
								// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<Fragment key={index}>
											<CollapseButton
												onClick={() => {
													let a = [...collapse];
													if (
														!a.filter((el) => el === row.serialNumber).length
													) {
														a.push(row.serialNumber);
													} else {
														a = a.filter((el) => el !== row.serialNumber);
													}
													setCollapse(a);
												}}
												boolean={Boolean(
													collapse.filter((el) => el === row.serialNumber)
														.length
												)}
											/>
											<HandlerRowDesktopAndMobile
												headCells={headCells}
												handleClick={() => {
													let a = [...collapse];
													if (
														!a.filter((el) => el === row.serialNumber).length
													) {
														a.push(row.serialNumber);
													} else {
														a = a.filter((el) => el !== row.serialNumber);
													}
													setCollapse(a);
												}}
												row={row}
												cursor
												checkboxnone
												column
												index={index}
												minimize
											/>
											<ItemTableList
												cls={cls}
												row={row}
												collapse={collapse}
												newRows={newRows}
												selected={selected}
												setSelected={(el) => {
													setSelected(el);
													// setCheckSelect(true);
													dispatch({ type: Types.RESET_SUCCESS });
												}}
											/>
										</Fragment>
									);
								})}
							{!newRows.length && rows.length && (
								<TableRow style={{ height: 53 * 1 }}>
									<TableCell colSpan={6}>
										{t(
											"По вашему фильтру ничего не найдено, выберите другие настройки"
										)}
										<div
											className={cls.reset_filter}
											onClick={() => setFilterActive({})}
										>
											{t("сбросить фильтр")}
										</div>
									</TableCell>
								</TableRow>
							)}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				{/* <EnhancedTablePagination
                count={newRows.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}

            /> */}

				{role1 && (
					<div className={cls.equipment_and_services_button_block}>
						{selected.length > 0 ? (
							<ButtonStyle onClick={() => prepareOrderPay(selected)}>
								{t("Сформировать заказ")}
							</ButtonStyle>
						) : (
							<ButtonStyle disabled>{t("Сформировать заказ")}</ButtonStyle>
						)}
					</div>
				)}
			</Paper>
		</>
	);
};

export default EquipmentAndServices;
