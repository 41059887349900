import React from "react";
import cls from "./modalContentWarning.module.scss";
import { useTranslation } from "react-i18next";

const ModalContentWarning = () => {
	const { t } = useTranslation();
	return (
		<div className={cls.modal_content_wrapper}>
			<div className={cls.modal_content_head}>
				<h2>{t("Имеется пропуск дат, вы желаете оплатить?")}</h2>
			</div>
		</div>
	);
};

export default ModalContentWarning;
