import React from 'react';
import cls from './loading.module.scss'
import Skeleton from '@material-ui/lab/Skeleton';

const Loading = ({load}) => {
    if(!load){
        return null;
    }
    return (
        <div className={cls.loading_container_wrapper}>
            <Skeleton animation="wave" variant="rect" className={cls.loading_skeleton} />
        </div>
    )
}

export default Loading;