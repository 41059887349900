import React from "react";
import PropTypes from "prop-types";
import cls from "./button.module.scss";
import { Button } from "@material-ui/core";

const ButtonStyle = ({
	children,
	variant,
	color,
	onClick,
	styleVariable,
	type,
	endIcon,
	disabled,
	component,
	fullWidth,
	href,
	target,
	rel,
}) => {
	const styleVariant = {
		default: cls.button_default,
		outlined: cls.button_outlined,
	};

	return (
		<Button
			target={target}
			rel={rel}
			href={href}
			fullWidth={fullWidth}
			component={component}
			disabled={disabled}
			endIcon={endIcon}
			type={type}
			className={disabled ? "" : styleVariant[styleVariable]}
			variant={variant}
			color={color}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

ButtonStyle.propTypes = {
	children: PropTypes.any,
	component: PropTypes.string,
	variant: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func,
	styleVariable: PropTypes.string,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	href: PropTypes.string,
	target: PropTypes.string,
	rel: PropTypes.string,
};

ButtonStyle.defaultProps = {
	children: "",
	component: "button",
	variant: "contained",
	color: "default",
	onClick: () => {},
	styleVariable: "default",
	disabled: false,
	fullWidth: false,
	href: "",
	target: "",
	rel: "",
};

export default ButtonStyle;
