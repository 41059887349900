import React from "react";
import cls from "./ModalContentOrderList.module.scss";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	Typography,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	IconButton,
	useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ArrowUpward, ArrowDownward, Navigation } from "@material-ui/icons";
import transactions from "../../../../redux/actions/transactions";

const ModalContentOrderList = () => {
	const dispatch = useDispatch();

	const { orderList } = useSelector((state) => state.transactions);

	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

	const orderListD = orderList.data1;
	const orderListE = orderList.data2;

	return (
		<div className={cls.modal_content_wrapper}>
			<div className={cls.modal_content_head}>
				<h2>
					<Trans>Банки - эквайеры</Trans>
				</h2>
			</div>
			<div className={cls.modal_content_main}>
				<Typography style={{ paddingBottom: "20px" }}>
					<Trans>
						Установите приоритеты для выбора банков-эквайеров при выполнении
						операций по карточкам.
						<br />
						Для изменения приоритетов необходимо нажать на стрелки.
						<br />
						Для перемещения{" "}
						<i style={{ textDecoration: "underline" }}>на уровень выше</i>{" "}
						нажмите <b style={{ color: "#109618" }}>зеленую</b> стрелку напротив
						наименования банка.
						<br />
						Для перемещения{" "}
						<i style={{ textDecoration: "underline" }}>на уровень ниже</i>{" "}
						нажмите <b style={{ color: "#ca2a38" }}>красную</b> стрелку.
					</Trans>
				</Typography>
				<Grid
					container
					spacing={isSmall ? 3 : 10}
					justifyContent='space-between'
				>
					<Grid item xs={12} md={6}>
						<Typography>
							<b>
								<Trans>По картам резидентов</Trans>
							</b>
						</Typography>
						<TableContainer className={cls.container_wrapper}>
							<Table className={cls.table_container}>
								<TableBody>
									{orderListD &&
										orderListD.map((row) => {
											return (
												<TableRow key={row.id}>
													<TableCell
														style={{ paddingLeft: 8, paddingRight: 0 }}
													>
														{row.flags ? (
															<div
																style={{
																	display: "flex",
																	gap: 4,
																	alignItems: "center",
																}}
															>
																<Navigation
																	fontSize='small'
																	style={{ transform: "rotate(90deg)" }}
																/>
																<b>{row.nm}</b>
															</div>
														) : (
															row.nm
														)}
													</TableCell>
													<TableCell padding='none' style={{ width: "100px" }}>
														<IconButton
															aria-label='up'
															onClick={() => {
																dispatch(
																	transactions.orderListUp(row, () =>
																		dispatch(transactions.orderList({}))
																	)
																);
															}}
														>
															<ArrowUpward style={{ color: "#109618" }} />
														</IconButton>
														<IconButton
															aria-label='down'
															onClick={() =>
																dispatch(
																	transactions.orderListDown(row, () =>
																		dispatch(transactions.orderList({}))
																	)
																)
															}
														>
															<ArrowDownward style={{ color: "#ca2a38" }} />
														</IconButton>
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography>
							<b>
								<Trans>По картам нерезидентов</Trans>
							</b>
						</Typography>
						<TableContainer className={cls.container_wrapper}>
							<Table className={cls.table_container}>
								<TableBody>
									{orderListE &&
										orderListE.map((row) => {
											return (
												<TableRow key={row.id}>
													<TableCell
														style={{ paddingLeft: 8, paddingRight: 0 }}
													>
														{row.flags ? (
															<div
																style={{
																	display: "flex",
																	gap: 4,
																	alignItems: "center",
																}}
															>
																<Navigation
																	fontSize='small'
																	style={{ transform: "rotate(90deg)" }}
																/>
																<b>{row.nm}</b>
															</div>
														) : (
															row.nm
														)}
													</TableCell>
													<TableCell padding='none' style={{ width: "100px" }}>
														<IconButton
															aria-label='up'
															onClick={() =>
																dispatch(
																	transactions.orderListUp(row, () =>
																		dispatch(transactions.orderList({}))
																	)
																)
															}
														>
															<ArrowUpward style={{ color: "#109618" }} />
														</IconButton>
														<IconButton
															aria-label='down'
															onClick={() =>
																dispatch(
																	transactions.orderListDown(row, () =>
																		dispatch(transactions.orderList({}))
																	)
																)
															}
														>
															<ArrowDownward style={{ color: "#ca2a38" }} />
														</IconButton>
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default ModalContentOrderList;
