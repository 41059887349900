const Language = () => {
	let language = 'ru';
	const lngStorage = localStorage.getItem('language');

	if (lngStorage && (lngStorage === 'en' || lngStorage === 'ru')) {
		language = lngStorage;
	} else {
		localStorage.setItem('language', language);
	};

	return language;
};

export default Language;