import types from './../Types'
import { toast } from 'react-toastify';

const addUser = (params, callback) => { // Добавление дополнительного пользователя
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.SETTINGS_REQUEST });

    return api({
      method: 'POST',
      url: '/api/cabinet/settings/user/add',
      data: params,
    }).then(
      (data) => {
        toast.success('Пользователь добавлен');
        dispatch({ type: types.SETTINGS_ADD_USER_SUCCESS, addUser: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.SETTINGS_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

const editUser = (params, callback) => { // Изменить ФИО дополнительного пользователя
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.SETTINGS_REQUEST });

    return api({
      method: 'POST',
      url: '/api/cabinet/settings/user/edit',
      data: params,
    }).then(
      (data) => {
        toast.success('ФИО успешно изменено');
        dispatch({ type: types.SETTINGS_EDIT_USER_SUCCESS, editUser: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.SETTINGS_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

const delUser = (params, callback) => { // Удаление дополнительного пользователя
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.SETTINGS_REQUEST });

    return api({
      method: 'POST',
      url: '/api/cabinet/settings/user/del',
      data: params,
    }).then(
      (data) => {
        toast.success('Пользователь удалён');
        dispatch({ type: types.SETTINGS_DEL_USER_SUCCESS, delUser: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.SETTINGS_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

const listUser = (params, callback) => { // Список дополнительных пользователей
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.SETTINGS_REQUEST });

    return api({
      method: 'POST',
      url: '/api/cabinet/settings/user/list',
      data: params,
    }).then(
      (data) => {
        dispatch({ type: types.SETTINGS_LIST_USER_SUCCESS, listUser: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.SETTINGS_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

const getEmail = (params, callback) => { // Получить емаил
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.SETTINGS_REQUEST });

    return api({
      method: 'POST',
      url: '/api/cabinet/settings/merchant/get',
      data: params,
    }).then(
      (data) => {
        dispatch({ type: types.SETTINGS_GET_EMAIL_SUCCESS, getEmail: data.data.email });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.SETTINGS_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

const editEmail = (params, callback) => { // Изменить емаил
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.SETTINGS_REQUEST });

    return api({
      method: 'POST',
      url: '/api/cabinet/settings/merchant/edit',
      data: params,
    }).then(
      (data) => {
        dispatch({ type: types.SETTINGS_EDIT_EMAIL_SUCCESS, editEmail: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.SETTINGS_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addUser,
  editUser,
  delUser,
  listUser,
  getEmail,
  editEmail,
};