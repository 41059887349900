import types from '../Types'

const initialvalues = {
  isRequested: false,
  error: '',
  addUser: {},
  editUser: {},
  delUser: {},
  listUser: [],
  getEmail: '',
  editEmail: {},
};

const settings = (state = initialvalues, action) => {
  switch (action.type) {
    case types.SETTINGS_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: '',
      };
    case types.SETTINGS_ADD_USER_SUCCESS:
      return {
        ...state,
        isRequested: false,
        addUser: action.addUser,
      };
    case types.SETTINGS_EDIT_USER_SUCCESS:
      return {
        ...state,
        isRequested: false,
        editUser: action.editUser,
      };
    case types.SETTINGS_DEL_USER_SUCCESS:
      return {
        ...state,
        isRequested: false,
        delUser: action.delUser,
      };
    case types.SETTINGS_LIST_USER_SUCCESS:
      return {
        ...state,
        isRequested: false,
        listUser: action.listUser,
      };
    case types.SETTINGS_GET_EMAIL_SUCCESS:
      return {
        ...state,
        isRequested: false,
        getEmail: action.getEmail,
      };
    case types.SETTINGS_EDIT_EMAIL_SUCCESS:
      return {
        ...state,
        isRequested: false,
        editEmail: action.editEmail,
      };
    case types.SETTINGS_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default settings;
