import React from "react";
import PropTypes from "prop-types";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Toolbar, Typography, IconButton, Tooltip } from "@material-ui/core";
import cls from "../../transactions.module.scss";
import { useTranslation } from "react-i18next";

const EnhancedTableToolbar = ({
	numSelected,
	setFilterOpen,
	filterActive,
	setFilterActive,
	title,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<Toolbar className={cls.toolbar}>
				<Typography
					className={cls.title}
					variant='h5'
					component='h1'
					id='tableTitle'
				>
					<span>{title}</span>
					{numSelected > 0 && (
						<span className={cls.toolbar_selected}>
							{numSelected} {t("Выбрано")}
						</span>
					)}
				</Typography>
				<Tooltip
					title={t("Список фильтров")}
					onClick={setFilterOpen}
					className={cls.filter_button}
				>
					<IconButton aria-label={t("Список фильтров")}>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			</Toolbar>
			<div className={cls.block_filter_all_wrapper}>
				{Object.keys(filterActive).map((el, index) => {
					let a = filterActive[el];

					return (
						<div
							key={index}
							className={cls.current_block_filter_wrapper}
							title={filterActive[el]}
							onClick={() => {
								const newFilterActive = { ...filterActive };
								delete newFilterActive[el];
								setFilterActive(newFilterActive);
							}}
						>
							<div className={cls.current_block_filter}>{a}</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number,
	setFilterOpen: PropTypes.func.isRequired,
};

export default EnhancedTableToolbar;
