import React, { useState, useEffect, useReducer, Fragment } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Switch,
	FormControl,
	Select,
	MenuItem,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ErrorIcon from "@material-ui/icons/Error";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Modal from "../Modal";
import ModalContent from "./components/Modals/ModalContent";
import ModalContentWarning from "./components/Modals/ModalContentWarning";
import ModalWarningPeriod from "./components/Modals/ModalWarningPeriod";
import cls from "./prepareOrderPay.module.scss";
import EnhancedTableHead from "./components/EnhancedTableHead";
import EnhancedTableToolbar from "./components/EnhancedTableToolbar";
import HandlerRowDesktopAndMobile from "../HandlerRowDesktopAndMobile";
import Loading from "../Loading";
import ButtonStyle from "../ButtonStyle";
import HintComponent from "../HintComponent";
import { useTranslation, Trans } from "react-i18next";
import DataPickerComponent from "../DataPickerComponent";
import moment from "moment";
import { toast } from "react-toastify";

const PrepareOrderPay = ({
	rows,
	params,
	resetOrder,
	warning,
	error,
	loading,
	flagAuto,
	errorBalance = false,
	errorTerm = false,
	preOrderParams = false,
	errorExpDates = false,
	errorDates = false,
	orderTerms,
	orderTariffs,
	dispatchPrepareOrder,
	dispatchOrderPay,
}) => {
	const { t } = useTranslation();
	const headCells = [
		{
			id: "equType",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Устройство"),
			bool: false,
			mobileLine: false,
		},
		{
			id: "sbName",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Комплект ПО"),
			bool: false,
			mobileLine: false,
		},
		{
			id: "tariffName",
			combobox: true,
			comboboxData: orderTariffs,
			numeric: false,
			disablePadding: false,
			align: "left",
			label: t("Тариф"),
			bool: false,
			mobileLine: false,
		},
		{
			id: "price_total",
			numeric: true,
			disablePadding: false,
			align: "right",
			label: t("Цена"),
			bool: false,
			mobileLine: true,
			prefix: t("руб"),
		},
	];

	const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
	const newBeginDate = new Date(params.beginDate.replace(pattern, "$3-$2-$1"));

	const [startDate, setStartDate] = useState(newBeginDate);
	const [period, setPeriod] = useState("");
	const [tariff, setTariff] = useState(0);
	const [checked, setChecked] = useReducer(
		(checked) => !checked,
		Boolean(flagAuto)
	);
	const [openModal, setOpenModal] = useState(false);
	const [openModalWarning, setOpenModalWarning] = useState(false);
	const [openModalPeriod, setOpenModalPeriod] = useState(false);

	const paymentClickHandler = () => {
		setOpenModalWarning(false);

		const wrap = {
			orderParams: params,
			orderData: rows,
		};
		dispatchOrderPay(wrap, () => setOpenModal(true));
	};

	const handlePeriod = (e) => {
		params.termID = e.target.value;

		setPeriod(params.termID);
	};

	const handleTariff = (e, row) => {
		row.tariffId = e.target.value;
		setTariff(row.tariffId);

		const wrap = {
			orderParams: params,
			orderData: rows,
		};
		dispatchPrepareOrder(wrap);
	};

	useEffect(() => {
		const wrap = {
			orderParams: {
				beginDate: moment(startDate).format("DD.MM.YYYY"),
				flagAuto: checked ? 1 : 0,
				termID: params.termID,
			},
			orderData: rows,
		};
		dispatchPrepareOrder(wrap);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, period, tariff, checked]);

	return (
		<Paper className={cls.prepare_order_pay_paper_wrapper}>
			<Loading load={loading} />
			<EnhancedTableToolbar params={params} />
			<TableContainer style={{ overflowX: "visible" }}>
				<Table
					className={cls.prepare_order_pay_table_min}
					aria-labelledby='tableTitle'
					size='medium'
					aria-label='enhanced table'
				>
					<EnhancedTableHead headCells={headCells} />
					<TableBody>
						{rows.map((row, index) => {
							return (
								<Fragment key={index}>
									<HandlerRowDesktopAndMobile
										headCells={headCells}
										row={row}
										column
										index={index}
										minimize
										className={`${
											row.orderErrorDates.length
												? cls.prepare_order_pay_error_message_rows
												: row.orderWarningDates.length
												? cls.rows_wrapper
												: ""
										}`}
										tariff={row.tariffId}
										handleTariff={(e) => handleTariff(e, row)}
									/>

									{row.orderErrorDates.map((el, index) => (
										<TableRow key={index}>
											<TableCell colSpan={4}>
												<div className={cls.prepare_order_pay_error_message}>
													<HighlightOffIcon /> {t("Пересекается с заказом №")}
													{el.orderNum} {t("от")} {el.servDateStart} {t("по")}{" "}
													{el.servDateStop}{" "}
													{Boolean(el.flagAuto)
														? t("(включен атрибут «Автоповтор»)")
														: ""}
													{Boolean(el.flagAuto)
														? (errorDates = true)
														: errorDates}{" "}
													{/*Контролим Автоповтор*/}
												</div>
											</TableCell>
										</TableRow>
									))}
									{row.orderErrorExpDates.map((el, index) => (
										<TableRow key={index}>
											<TableCell colSpan={4}>
												<div className={cls.prepare_order_pay_error_message}>
													<HighlightOffIcon />{" "}
													{t(
														"Срок действия договора оферты на выбранное оборудование ограничен"
													)}{" "}
													{el.expDate}
												</div>
											</TableCell>
										</TableRow>
									))}
									{row.orderWarningDates.map((el, index) => (
										<TableRow key={index}>
											<TableCell colSpan={4}>
												<div className={cls.prepare_order_pay_error_message}>
													<ErrorIcon style={{ color: "#FEFE00" }} />{" "}
													{t("Имеется пропуск дат с")} {el.dateStart} {t("по")}{" "}
													{el.dateStop}
												</div>
											</TableCell>
										</TableRow>
									))}
								</Fragment>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<div className={cls.block_inform_container_wrapper}>
				<section className={cls.prepare_order_inform__wrapper}>
					<div className={cls.prepare_order_inform__period}>
						{t("Выберите период")}
						<FormControl variant='outlined'>
							<Select
								// value={params.termID}
								defaultValue={""}
								onChange={(e) => handlePeriod(e)}
								className={cls.style_select}
							>
								{orderTerms.map((row) => {
									return (
										<MenuItem
											key={row.termID}
											value={row.termID}
										>
											{row.termName}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
					<div className={cls.prepare_order_inform__date_block}>
						{t("включить с")}:
						<DataPickerComponent
							minDate={new Date()}
							selected={startDate}
							onChange={setStartDate}
						/>
						{t("по")}: {params.endDate}
					</div>
					<div className={cls.switch_wrapper}>
						<Switch
							checked={checked}
							onChange={setChecked}
							className={cls.switch_style_active}
						/>
						{t("Автоповтор")}
					</div>
				</section>

				<div className={cls.price_order_wrapper}>
					{`${t("К оплате")}: ${params.price_order.toFixed(2)} ${t("руб")}`}
					<HintComponent>
						{t("Резиденты Парка высоких технологий (ПВТ) освобождены от НДС")}
					</HintComponent>
				</div>
			</div>
			<div className={cls.button_block}>
				{!errorExpDates && !error && !errorBalance ? (
					<>
						{Object.keys(preOrderParams).length === 0 && (
							<ButtonStyle
								onClick={() => {
									window.scrollTo({
										top: 0,
										behavior: "smooth",
									});
									resetOrder();
								}}
								styleVariable='outlined'
							>
								{t("Отменить")}
							</ButtonStyle>
						)}
						<ButtonStyle
							onClick={() => {
								if (period !== "") {
									if (!warning) paymentClickHandler();
									else setOpenModalWarning(true);
								} else toast.error("Выберите период");
							}}
						>
							{preOrderParams ? t("Активировать") : t("Оплатить")}
						</ButtonStyle>
						<Modal
							open={openModal}
							close={() => {
								resetOrder();
								setOpenModal(false);
							}}
						>
							<ModalContent
								close={() => {
									resetOrder();
									setOpenModal(false);
								}}
							/>
						</Modal>
						<Modal
							open={openModalWarning}
							close={() => {
								setOpenModalWarning(false);
							}}
							titleButton={t("Оплатить")}
							styleButton
							buttonClick={paymentClickHandler}
						>
							<ModalContentWarning />
						</Modal>
					</>
				) : errorBalance ? (
					<ButtonStyle styleVariable='outlined'>
						{t("Недостаточно средств")}
					</ButtonStyle>
				) : errorTerm ? (
					<ButtonStyle styleVariable='outlined'>
						{t("Недоступно для выбранных услуг")}
					</ButtonStyle>
				) : (
					<>
						{params.flagAuto === 1 && errorDates ? (
							<Alert
								className={cls.button_block__text}
								severity='error'
							>
								<Trans>
									Для оформления нового заказа необходимо выключить «Автоповтор»
									у ранее сделанного заказа Для этого зайдите в меню «Мои
									заказы» и в детализации указанного заказа отключите
									переключатель «Автоповтор» и нажмите кнопку «Сохранить» для
									применения изменений
								</Trans>
							</Alert>
						) : (
							<>
								<Alert
									className={cls.button_block__text}
									severity='warning'
								>
									<Trans>
										Имеются пересечения по датам с ранее заключенными заказами
										Измените дату начала действия нового заказа Для этого
										нажмите кнопку календаря для изменения и выберите другую
										дату начала действия заказа
									</Trans>
								</Alert>
								<ButtonStyle
									onClick={() => {
										if (period === "") toast.error("Выберите период");
										else setOpenModalPeriod(true);
									}}
								>
									{t("Продолжить")}
								</ButtonStyle>
							</>
						)}
						<ButtonStyle
							onClick={() => {
								window.scrollTo({
									top: 0,
									behavior: "smooth",
								});
								resetOrder();
							}}
							styleVariable='outlined'
						>
							{t("Отменить")}
						</ButtonStyle>
						<Modal
							open={openModalPeriod}
							close={() => {
								resetOrder();
								setOpenModalPeriod(false);
							}}
							styleButton
							titleButton={t("Подтвердить")}
							buttonClick={() => {
								params.flagIgnoreCrossOrder = 1;

								if (params.flagIgnoreCrossOrder === 1) paymentClickHandler();

								setOpenModalPeriod(false);
							}}
							titleButton2={t("Отменить")}
						>
							<ModalWarningPeriod />
						</Modal>
						<Modal
							open={openModal}
							close={() => {
								resetOrder();
								setOpenModal(false);
							}}
						>
							<ModalContent />
						</Modal>
					</>
				)}
			</div>
		</Paper>
	);
};

export default PrepareOrderPay;
