import types from '../Types'

const initialvalues = {
  isRequested: false,
  error: '',
  devicesLength: 0,
  devices: [],
  tranList: {},
  batchList: [],
  batchTran: [],
  emailBatch: {},
  emailBatchTran: {},
  analyticEmi: {},
  orderList: {},
  orderListUp: {},
  orderListDown: {},
};

const transactions = (state = initialvalues, action) => {
  switch (action.type) {
    case types.TRANSACTIONS_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: '',
      };
    case types.TRANSACTIONS_DEVICES_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        devices: action.devices,
        devicesLength: action.devicesLength
      };
    case types.TRANSACTIONS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        tranList: action.tranList,
      }
    case types.TRANSACTIONS_BATCH_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        batchList: action.batchList,
      };
    case types.TRANSACTIONS_EMAIL_BATCH_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        emailBatch: action.emailBatch,
      }
    case types.TRANSACTIONS_BATCH_TRAN_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        batchTran: action.batchTran,
      };
    case types.TRANSACTIONS_EMAIL_BATCH_TRAN_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        emailBatchTran: action.emailBatchTran,
      }
    case types.TRANSACTIONS_ANALYTIC_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        analyticEmi: action.analyticEmi,
      };
    case types.TRANSACTIONS_ORDER_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        orderList: action.orderList,
      };
    case types.TRANSACTIONS_ORDER_LIST_UP_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        orderListUp: action.orderListUp,
      };
    case types.TRANSACTIONS_ORDER_LIST_DOWN_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        orderListDown: action.orderListDown,
      };
    case types.TRANSACTIONS_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default transactions;
