import React from "react";
import cls from "./login.module.scss";
import {
	CssBaseline,
	Container,
	CircularProgress,
	Card,
} from "@material-ui/core";
import { useFormik } from "formik";
import InputForm from "../../components/form/InputForm";
import validate from "./validate";
import { useDispatch, useSelector } from "react-redux";
import authentication from "../../redux/actions/authentication";
import ButtonStyle from "../../components/ButtonStyle";
import { Link, useHistory } from "react-router-dom";
import logo from "../../img/zPOS_logo.svg";

const Restore = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const loading = useSelector((state) => state.authentication.isRequested);
	const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
		useFormik({
			initialValues: {
				login: "",
			},
			validate: (values) => validate(values),
			onSubmit: ({ login }) => {
				dispatch(
					authentication.forgotPassword(login, () => history.push("./"))
				);
			},
		});
	return (
		<Container
			component='main'
			maxWidth='sm'
			className={cls.login_container_wrapper}
		>
			<Card className={cls.login_block_card}>
				<CssBaseline />
				<div className={cls.login_block_content}>
					<a href='https://zpos.by/'>
						<img src={logo} alt='logo' />
					</a>
					<h1 className={cls.login_block_h1}>ЗАПРОС ВОССТАНОВЛЕНИЯ ПАРОЛЯ</h1>
					<form onSubmit={handleSubmit}>
						<div className={cls.block_text}>Пожалуйста укажите ваш логин</div>
						<InputForm
							handleChange={handleChange}
							handleBlur={handleBlur}
							values={values}
							fullWidth
							name='login'
							// autoFocus
							label='логин'
							touched={touched}
							errors={errors}
							disabled={loading}
						/>
						<div className={cls.login_button_wraper}>
							<ButtonStyle type='submit' disabled={loading}>
								Отправить запрос
							</ButtonStyle>
							{loading && (
								<CircularProgress size={24} className={cls.login_loading} />
							)}
						</div>
					</form>
					<div className={cls.login_remember_me_container}>
						<Link to='/'>вернуться назад</Link>
					</div>
				</div>
			</Card>
		</Container>
	);
};

export default Restore;
