import types from '../Types'

const initialvalues = {
  isRequested: false,
  error: '',
  orders: [],
  order: [],
  ordersFree: [],
  orderUpdate: {
    error: 0
  }
};

const orders = (state = initialvalues, action) => {
  switch (action.type) {
    case types.ORDER_UPDATE_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.ORDER_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        orderUpdate: action.orderUpdate,
      };
    case types.ORDER_UPDATE_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.ORDERS_REQUEST_FREE:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.ORDERS_REQUEST_FREE_SUCCESS:
      return {
        ...state,
        isRequested: false,
        ordersFree: action.ordersFree,
      };
    case types.ORDERS_REQUEST_FREE_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.ORDERS_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.ORDERS_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        orders: action.orders,
      };
    case types.ORDERS_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.CURRENT_ORDER_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.CURRENT_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        order: action.order,
      };
    case types.CURRENT_ORDER_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default orders;
