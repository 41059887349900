import types from '../Types'

const initialvalues = {
  isRequested: false,
  error: '',
  myHistory: [],
};

const historyReducer = (state = initialvalues, action) => {
  switch (action.type) {
    case types.MY_HISTORY_OPERATION:
      return { ...state, isRequested: true, error: '' };
    case types.MY_HISTORY_OPERATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isRequested: false,
        myHistory: action.myHistory,
      };
    case types.MY_HISTORY_OPERATION_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default historyReducer;
