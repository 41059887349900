import React, { useEffect, useState, Fragment } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Toolbar,
	Typography,
	Paper,
	Accordion,
	AccordionDetails,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import cls from "../../transactions.module.scss";
import "./BatchTran.module.scss";

import SortHelper from "../../../../helpers/sortHelper";
import { useHandleRequestSort } from "../../../../helpers/handleRequestSort";
// import movementInRef from "../../../../helpers/movementInRef";

import EnhancedTableHead from "../EnhancedTableHead";
import EnhancedTableToolbar from "../EnhancedTableToolbar";
import HandlerRowDesktopAndMobile from "../../../../components/HandlerRowDesktopAndMobile";
import CollapseButton from "../../../../components/CollapseButton";
import BatchTran from "./BatchTran";
import Loading from "../../../../components/Loading";
import ModalFilter from "../../../../components/ModalFilter";
import EnhancedTablePagination from "../../../../components/EnhancedTablePagination";

const BatchList = ({ loading, rows, title, dispatchEmailBatch }) => {
	const { t } = useTranslation();

	const headCells = [
		{
			id: "openDt",
			numeric: false,
			disablePadding: true,
			align: "center",
			label: t("Дата открытия"),
			bool: false,
			mobileLine: true,
			sort: true,
		},
		{
			id: "closeDt",
			numeric: false,
			disablePadding: true,
			align: "center",
			label: t("Дата закрытия"),
			bool: false,
			mobileLine: false,
			sort: false,
		},
		{
			id: "sn",
			numeric: false,
			disablePadding: true,
			align: "center",
			label: t("SN"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "cati",
			numeric: false,
			disablePadding: true,
			align: "center",
			label: t("TID, Банк-Эквайер"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "debNum",
			numeric: true,
			disablePadding: true,
			align: "center",
			label: t("Дебет. Кол-во"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "debAmt",
			numeric: false,
			disablePadding: true,
			align: "center",
			label: t("Дебет. Сумма"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "credNum",
			numeric: true,
			disablePadding: true,
			align: "center",
			label: t("Кредит. Кол-во"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "credAmt",
			numeric: false,
			disablePadding: true,
			align: "center",
			label: t("Кредит. Сумма"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "totAmt",
			numeric: false,
			disablePadding: true,
			align: "center",
			label: t("Итоговая сумма"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
	];

	const headCellsFilter = headCells.filter((cell) => cell.id !== "closeDt"); // Убираем фильтр по "Дата закрытия"

	const [{ order, orderBy }, handleRequestSort] = useHandleRequestSort({
		order: "desc",
		orderBy: "openDt",
	});

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		rowsPerPage -
		Math.min(rowsPerPage, (rows ? rows.length : 0) - 0 * rowsPerPage);

	const [expanded, setExpanded] = useState(false);
	const handleChange = (panel) => {
		setExpanded(expanded !== panel ? panel : false);
	};
	// useEffect(() => {
	// 	if (expanded) movementInRef(currentBatchTranRef);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [expanded]);
	// const currentBatchTranRef = useRef();

	const [filterOpen, setFilterOpen] = useState(false);
	const [filterActive, setFilterActive] = useState({});

	const handleChangeFilterRows = (array, params) => {
		return array.filter((el) => {
			if (Object.keys(params).length) {
				let status = [];
				Object.keys(params).forEach((key) => {
					if (el[key] === params[key]) {
						status.push(true);
					} else {
						let statusEl = false;
						if (Array.isArray(params[key])) {
							params[key].forEach((parEl) => {
								if (Boolean(el[key].find((elEl) => elEl === parEl))) {
									statusEl = true;
								}
							});
						}
						statusEl ? status.push(true) : status.push(false);
					}
				});
				let finishSearch = false;
				if (status.find((bool) => bool === false) === undefined) {
					finishSearch = true;
				}
				return finishSearch;
			}
			return true;
		});
	};

	const [newRows, setNewRows] = useState(
		handleChangeFilterRows(rows, filterActive)
	);

	useEffect(() => {
		setNewRows(handleChangeFilterRows(rows, filterActive));
	}, [filterActive, rows]);

	// useEffect(() => {
	// 	movementInRef(refBatchList);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [rows]);

	return (
		<Paper className={cls.paper}>
			{rows?.length ? (
				<>
					<EnhancedTableToolbar
						filterActive={filterActive}
						setFilterActive={setFilterActive}
						setFilterOpen={() => setFilterOpen(!filterOpen)}
						title={title}
					/>
					<TableContainer
						// style={{ overflowX: "inherit" }}
						className={cls.container_wrapper}
					>
						<Loading load={loading} />
						<Table
							className={`${cls.table_container} ${cls.minimize_padding}`}
							aria-labelledby='tableTitle'
							size='medium'
							aria-label='enhanced table'
						>
							<EnhancedTableHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								headCells={headCells}
								sort
								email
							/>
							<ModalFilter
								setFilterOpen={() => setFilterOpen(false)}
								filterOpen={filterOpen}
								setFilterActive={setFilterActive}
								headCells={headCellsFilter}
								rows={rows}
								filterActive={filterActive}
								filterRows={newRows}
							/>
							<TableBody>
								{SortHelper(newRows, order, orderBy)
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										return (
											<Fragment key={index}>
												<CollapseButton
													onClick={() => handleChange(row.id)}
													boolean={expanded !== row.id}
												/>
												<HandlerRowDesktopAndMobile
													headCells={headCells}
													handleClick={(e, row) => handleChange(row.id)}
													row={row}
													index={index}
													cursor
													paddingCheck
													minimize
													email
													batchList
													handleClickEmail={(e) => {
														e.stopPropagation();
														dispatchEmailBatch({ id: row.id }, (data) => {
															const response = data.data;
															if (response.resCode !== 0)
																toast.error(response.resMsg);
															else toast.success(response.resMsg);
														});
													}}
												/>
												<TableRow>
													<TableCell colSpan={10} padding='none'>
														<Accordion expanded={expanded === row.id}>
															<AccordionDetails>
																{expanded === row.id && (
																	<div
																		// ref={currentBatchTranRef}
																		style={{ width: "100%" }}
																	>
																		<BatchTran row={row} />
																	</div>
																)}
															</AccordionDetails>
														</Accordion>
													</TableCell>
												</TableRow>
											</Fragment>
										);
									})}
								{!newRows.length && rows.length && (
									<TableRow style={{ height: 53 * 1 }}>
										<TableCell colSpan={10}>
											<div className={cls.filter_empty_text}>
												{t(
													"По вашему фильтру ничего не найдено, выберите другие настройки"
												)}
												<div
													className={cls.reset_filter}
													onClick={() => setFilterActive({})}
												>
													{t("сбросить фильтр")}
												</div>
											</div>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					{!emptyRows && (
						<EnhancedTablePagination
							count={newRows.length}
							page={page}
							rowsPerPage={rowsPerPage}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</>
			) : (
				<Toolbar className={cls.toolbar}>
					<Typography className={cls.title} variant='h5' id='batchListTitle'>
						<span>{t("Нет данных по бизнес-дням")}</span>
					</Typography>
				</Toolbar>
			)}
		</Paper>
	);
};

export default BatchList;
