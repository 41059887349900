import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import cls from "./moreHorizMenu.module.scss";
import { useTranslation } from "react-i18next";

const MoreHorizMenu = ({ clickDelete, clickChange }) => {
	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<>
			<tr onClick={handleClick} className={cls.more_horiz_menu}>
				<td className={cls.more_horiz_menu_container}>
					<MoreVertIcon />
				</td>
			</tr>
			<Menu
				keepMounted
				anchorEl={anchorEl}
				onClose={handleClose}
				open={Boolean(anchorEl)}
			>
				<MenuItem onClick={clickChange}>{t("Изменить")}</MenuItem>
				<MenuItem onClick={clickDelete}>{t("Удалить")}</MenuItem>
			</Menu>
		</>
	);
};

export default MoreHorizMenu;
