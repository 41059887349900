import React from "react";
import cls from "./information.module.scss";
import { useSelector } from "react-redux";
import { Card } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import Banner from '../../components/Banner';
import ButtonStyle from "../../components/ButtonStyle";
import HistoryComponent from "./component/HistoryComponent";
import HelmetComponent from "../../components/HelmetComponent";
import Skeleton from "@material-ui/lab/Skeleton";
import { useTranslation } from "react-i18next";
import HintComponent from "../../components/HintComponent";

const InformationUser = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { user, isRequested } = useSelector((state) => state.authentication);

	return (
		<main className={cls.information_container}>
			<HelmetComponent
				titlePage={t("Информация пользователя")}
				description={t("Информация пользователя, выписка по счёту")}
				title={t("Информация пользователя")}
			/>

			<Card className={cls.information_block_card}>
				{!isRequested ? (
					<div className={cls.information_block_wrapper}>
						<div className={cls.information_block_wrapper}>
							<div className={cls.information_user_block}>
								{t("На балансе")} {user.orgShortName}:{" "}
								<ButtonStyle onClick={() => history.push("/history")}>
									{user.accBalance ? user.accBalance.toFixed(2) : "0.00"}{" "}
									{t("руб")}
								</ButtonStyle>
							</div>
						</div>{" "}
						<div className={cls.information_block_wrapper}>
							<div className={cls.card_button_block}>
								<div>
									{t("Касс в наличии")}:{" "}
									<HintComponent>
										{t(
											"«Касс в наличии» - количество зарегистрированного оборудования (zPOSТерминалов) в Системе"
										)}
									</HintComponent>
								</div>{" "}
								<ButtonStyle
									styleVariable='outlined'
									onClick={() => history.push("/equipment")}
								>
									{user?.snCountAll ? `${user.snCountAll} ${t("шт")}` : "-"}
								</ButtonStyle>
							</div>
							<div className={cls.card_button_block}>
								<div>
									{" "}
									{t("Касс активно")}:
									<HintComponent>
										{t(
											"«Касс активно» - количество zPOSТерминалов (с услугой доступа к ПО «Программная касса»), которые на текущий момент включены в активные заказы (статус заказов «Действующий» или «Истекает»)"
										)}
									</HintComponent>
								</div>{" "}
								<ButtonStyle
									styleVariable='outlined'
									onClick={() =>
										history.push(
											"/equipment?servStatusAll%5B0%5D=warn&servStatusAll%5B1%5D=ok"
										)
									}
								>
									{user?.snCountOn ? `${user.snCountOn} ${t("шт")}` : "-"}
								</ButtonStyle>
							</div>
							<div className={cls.card_button_block}>
								<div>
									{t("Касс не активно")}:
									<HintComponent>
										{t(
											"«Касс не активно» - количество zPOSТерминалов (с услугой доступа к ПО «Программная касса»), которые на текущий момент времени не включены ни в один заказ или включены в заказы, у которых статус «Исполнен» или «Ожидает»"
										)}
									</HintComponent>
								</div>{" "}
								<ButtonStyle
									styleVariable='outlined'
									onClick={() =>
										history.push(
											"/equipment?servStatusAll%5B0%5D=new&servStatusAll%5B1%5D=pre"
										)
									}
								>
									{user?.snCountOff ? `${user.snCountOff} ${t("шт")}` : "-"}
								</ButtonStyle>
							</div>
							{user?.warningDays && (
								<div className={cls.card_button_block}>
									{t("До окончания ближайшей подписки осталось")}:{" "}
									<ButtonStyle
										styleVariable='outlined'
										onClick={() => history.push("/orders?orderStatus=warn")}
									>
										{user.warningDays} {t("дней")}
									</ButtonStyle>
								</div>
							)}
							{Boolean(user?.warningBallance) && (
								<div
									className={cls.error_message}
									onClick={() =>
										history.push("/orders?flagAuto=1&orderStatus=warn")
									}
								>
									{t("На балансе недостаточно ")}{" "}
									{user?.warningBallanceSum.toFixed(2)} {t("руб")}
									{t("для автоповтора заказов на подписки")}
								</div>
							)}
						</div>
					</div>
				) : (
					<div className={cls.information_block_wrapper}>
						<div className={cls.information_block_wrapper}>
							<div className={cls.information_user_block}>
								<Skeleton
									className={cls.information_user_block_balance}
									animation='wave'
								/>{" "}
								<ButtonStyle disabled>
									<Skeleton
										animation='wave'
										width={70}
									/>
								</ButtonStyle>
							</div>
						</div>{" "}
						<div className={cls.information_block_wrapper}>
							<div className={cls.card_button_block}>
								<Skeleton
									animation='wave'
									className={cls.card_button_block_1}
								/>
								<ButtonStyle
									styleVariable='outlined'
									disabled
								>
									<Skeleton
										animation='wave'
										width={50}
									/>
								</ButtonStyle>
							</div>
							<div className={cls.card_button_block}>
								<Skeleton
									animation='wave'
									className={cls.card_button_block_2}
								/>{" "}
								<ButtonStyle
									styleVariable='outlined'
									disabled
								>
									<Skeleton
										animation='wave'
										width={50}
									/>
								</ButtonStyle>
							</div>
							<div className={cls.card_button_block}>
								<Skeleton
									animation='wave'
									className={cls.card_button_block_3}
								/>{" "}
								<ButtonStyle
									styleVariable='outlined'
									disabled
								>
									<Skeleton
										animation='wave'
										width={50}
									/>
								</ButtonStyle>
							</div>
							<div className={cls.card_button_block}>
								<Skeleton
									animation='wave'
									className={cls.card_button_block_4}
								/>{" "}
								<ButtonStyle
									styleVariable='outlined'
									disabled
								>
									<Skeleton
										animation='wave'
										width={50}
									/>
								</ButtonStyle>
							</div>
						</div>
					</div>
				)}
			</Card>

			<HistoryComponent />
			{/* <div className={cls.information_baner_wrapper}><Banner /></div> */}
		</main>
	);
};

export default InformationUser;
