import React, { useEffect } from "react";
import cls from "./history.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "@material-ui/core";
import historyActions from "../../../../redux/actions/historyActions";
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ButtonStyle from "../../../../components/ButtonStyle";
import { useHistory, Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { useTranslation } from "react-i18next";

const HistoryComponent = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { isRequested, myHistory } = useSelector(
		(state) => state.historyReducer
	);
	const history = useHistory();
	useEffect(() => {
		dispatch(historyActions.historyOperationTop());
	}, [dispatch]);

	return (
		<Card className={cls.history_block_information_wrapper}>
			<div className={cls.title_wrapper}>
				<h2>{t("История операций")}</h2>
				<div className={cls.block_buttom_wrapper}>
					<ButtonStyle onClick={() => history.push("/history")}>
						{t("подробнее")}
					</ButtonStyle>
				</div>
			</div>
			<div className={cls.history_block_information}>
				{/* {!isRequested && myHistory.map((el, index) => {
                return (<div key={index} className={cls.content_block_wrapper}>
                    <div className={cls.content_wrapper}>
                        <span className={Boolean(el.operType) ? cls.sum_minus : cls.sum_plus}>{Boolean(el.operType) ? el.operAmount : `+${el.operAmount}`} {t('руб')}</span>
                        <div className={cls.content_date}>{el.operDt}</div>
                        {Boolean(el.operType) ? <Link className={cls.content_order_link} to={`/orders?orderNum=${el.orderNum}`}>  {`${t('Оформлен заказ')} №${el.orderNum}`} </Link> : <div className={cls.content_order}>{t('Пополнение лицевого счёта')}</div>}
                    </div>
                </div>)
            })} */}

				<table className={cls.table_wrapper}>
					<tbody>
						{!isRequested &&
							myHistory.map((el, index) => {
								return (
									<tr
										key={index}
										className={cls.content_wrapper}
									>
										<td
											className={
												Boolean(el.operType) ? cls.sum_minus : cls.sum_plus
											}
										>
											{Boolean(el.operType)
												? el.operAmount.toFixed(2)
												: `+${el.operAmount.toFixed(2)}`}{" "}
											{t("руб")}
										</td>
										<td className={cls.content_date}>{el.operDt}</td>
										<td>
											{Boolean(el.operType) ? (
												<Link
													className={cls.content_order_link_table}
													to={`/orders?orderNum=${el.orderNum}`}
												>
													{" "}
													{`${t("Оформлен заказ")} №${el.orderNum}`}{" "}
												</Link>
											) : (
												<div className={cls.content_order_table}>
													{t("Пополнение лицевого счёта")}
												</div>
											)}
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>

				{isRequested &&
					new Array(5).fill(1).map((el, index) => (
						<div
							key={index}
							className={cls.content_block_wrapper}
						>
							{/* <div><Skeleton animation="wave" variant="circle" width={24} height={24} /></div> */}
							<div className={cls.content_wrapper}>
								<span className={cls.sum_minus}>
									<Skeleton
										animation='wave'
										width={70}
									/>
								</span>
								<div className={cls.content_date}>
									<Skeleton
										animation='wave'
										width={82}
									/>
								</div>
								<div className={cls.content_order}>
									<Skeleton
										animation='wave'
										width={175}
									/>
								</div>
							</div>
						</div>
					))}
			</div>
		</Card>
	);
};
export default HistoryComponent;
