import React from "react";
import cls from "./collapseButton.module.scss";

const CollapseButton = ({ boolean, onClick }) => {
	return (
		<tr
			onClick={onClick}
			className={`${cls.collapse_button} ${
				boolean ? cls.collapse_button_active : ""
			}`}
		>
			<td>
				<div />
				<div />
			</td>
		</tr>
	);
};

export default CollapseButton;
