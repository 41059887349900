import React from "react";
import { Chart } from "react-google-charts";

const ChartPie = ({ data, options, width, height }) => {
	const language = localStorage.getItem("language");

	return (
		<Chart
			chartType='PieChart'
			data={data}
			options={options}
			width={width}
			height={height}
			chartLanguage={language}
		/>
	);
};

export default ChartPie;
