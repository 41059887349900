const handleClickRow = (event, row, selected, setSelected) => {
	const selectedIndex = selected.indexOf(row);
	let newSelected = [];
	if (selectedIndex === -1) {
		newSelected = newSelected.concat(selected, row);
	} else if (selectedIndex === 0) {
		newSelected = newSelected.concat(selected.slice(1));
	} else if (selectedIndex === selected.length - 1) {
		newSelected = newSelected.concat(selected.slice(0, -1));
	} else if (selectedIndex > 0) {
		newSelected = newSelected.concat(
			selected.slice(0, selectedIndex),
			selected.slice(selectedIndex + 1),
		);
	}

	setSelected(newSelected);
};
handleClickRow.handleSelectAllRows = (selected, rows, setSelected) => {
	if (!selected.length) {
		const newSelecteds = [...rows];
		setSelected(newSelecteds);
		return;
	}
	setSelected([]);
}
handleClickRow.handleSelectAllRowsCollapse = (selected, rows, setSelected) => {
	if (!selected.length) {
		let newSelecteds = [];
		rows.forEach(element => {
			newSelecteds = [...newSelecteds, ...element.items]
		});
		setSelected(newSelecteds);
		return;
	}
	setSelected([]);
}

export default handleClickRow;