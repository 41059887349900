import React from "react";
import cls from "../modalContent.module.scss";
import { useTranslation } from "react-i18next";

const ModalWarningPeriod = () => {
	const { t } = useTranslation();
	return (
		<div className={cls.modal_content_wrapper}>
			<div className={cls.modal_content_main}>
				{t(
					"Новый заказ пересекается по срокам с ранее сделанным заказом, но не отменяет его В период действия обоих заказов будет действовать тарифный план с более полными функциональными возможностями"
				)}
			</div>
		</div>
	);
};

export default ModalWarningPeriod;
