import React, { useState } from "react";
import cls from "./ModalContentSelect.module.scss";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, FormControl } from "@material-ui/core";

const ModalContentSelect = ({ isOpen, value, onChange, arr }) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(isOpen ? true : false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	return (
		<div className={cls.modal_content_wrapper}>
			<div className={cls.modal_content_head}>
				<h2>{t("Выберите организацию")}</h2>
			</div>
			<div className={cls.modal_content_main}>
				<FormControl variant='outlined' fullWidth={true}>
					<Select
						autoWidth
						value={value}
						onChange={onChange}
						className={cls.style_select}
						open={open}
						onOpen={handleOpen}
						onClose={handleClose}
					>
						{arr.map((item, index) => {
							return (
								<MenuItem key={index} value={item.unp}>
									{item.unp} {item.orgShortName ? `- ${item.orgShortName}` : ""}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</div>
		</div>
	);
};

export default ModalContentSelect;
