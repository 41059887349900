import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
	Paper,
	Box,
	Grid,
	Toolbar,
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import cls from "../../transactions.module.scss";

import Loading from "../../../../components/Loading";
import ChartBar from "../../../../components/ChartBar";
import ChartPie from "../../../../components/ChartPie";
import TabsComponenet from "../../../../components/Tabs";
import ButtonStyle from "../../../../components/ButtonStyle";
import Modal from "../../../../components/Modal";
import ModalContentOrderList from "../../../../components/Modal/components/ModalContentOrderList";

const Analytic = ({ loading, params, title, dispatchOrderList }) => {
	const { t } = useTranslation();

	const { isMultiAcquirer } = useSelector((state) => state.authentication.user);

	const [isVisible, setVisibleState] = useState(false);

	const tabsLabels = [t("По сумме"), t("По количеству")];
	const [tabsAnalytic, setTabsAnalytic] = useState(0);
	const tabsChange = (event, newValue) => {
		setTabsAnalytic(newValue);
	};

	// Bar
	const dataBar = params?.data2;
	const dataBarTitle1 = "Карты иных банков";
	const dataBarTitle2 = "Карты банков, с которыми заключен договор эквайринга";
	const dataBarNew = !tabsAnalytic
		? [
				["", dataBarTitle1, dataBarTitle2],
				["", 100 - dataBar?.amtP, dataBar?.amtP],
		  ]
		: [
				["", dataBarTitle1, dataBarTitle2],
				["", 100 - dataBar?.qtyP, dataBar?.qtyP],
		  ];

	const optionsBar = {
		title: "Доля операций по картам",
		// legend: { position: "none" },
		chartArea: { width: "25%" },
		colors: ["#ca2a38", "#4caf50"],
		isStacked: true,
		tooltip: { isHtml: true },
	};

	// Pie
	const dataPie = !tabsAnalytic ? params?.data1Amt : params?.data1Qty;

	const dataPieCircle = [["Банк", "Операции"]];

	const optionsPie = {
		title: "Распределение операций по банкам-эмитентам",
		is3D: true,
		legend: { textStyle: { fontSize: 8 } },
	};

	dataPie &&
		dataPie.map((el) => {
			if (!tabsAnalytic) {
				dataPieCircle.push([el?.em, parseFloat(el?.val)]);
			} else {
				dataPieCircle.push([el?.em, parseFloat(el?.val)]);
			}
			return el;
		});

	const dataPieTable = dataPieCircle.filter((_, i) => i !== 0);

	const headCells = !tabsAnalytic
		? [
				{
					id: "em",
					align: "left",
					label: t("Банк"),
				},
				{
					id: "amt",
					align: "right",
					label: t("Сумма, BYN"),
				},
		  ]
		: [
				{
					id: "em",
					align: "left",
					label: t("Банк"),
				},
				{
					id: "qty",
					align: "right",
					label: t("Количество, шт."),
				},
		  ];

	return (
		<Paper className={cls.paper}>
			<Loading load={loading} />
			{dataPie && dataBar ? (
				<>
					<Toolbar className={cls.toolbar}>
						<Typography className={cls.title} variant='h5' id='tableTitle'>
							<span>{title}</span>
						</Typography>
					</Toolbar>
					<Box>
						<Grid container className={cls.analytic_tabs}>
							<TabsComponenet
								onChange={tabsChange}
								value={tabsAnalytic}
								labels={tabsLabels}
							/>
						</Grid>
						<Grid container className={cls.analytic_charts}>
							<Grid item xs={12} sm={6}>
								<ChartPie
									data={dataPieCircle}
									options={optionsPie}
									width='100%'
									height='300px'
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<ChartBar
									data={dataBarNew}
									options={optionsBar}
									width='100%'
									height='300px'
								/>
							</Grid>
							<Grid container item xs={12} sm={6}>
								<TableContainer className={cls.container_wrapper}>
									<Table className={`${cls.table_container}`} size='small'>
										<TableHead>
											<TableRow>
												{headCells.map((cell) => {
													return (
														<TableCell key={cell.id} align={cell.align}>
															<b>{cell.label}</b>
														</TableCell>
													);
												})}
											</TableRow>
										</TableHead>
										<TableBody>
											{dataPieTable.map((row, index) => {
												return (
													<TableRow key={index}>
														<TableCell>{row[0]}</TableCell>
														<TableCell align='right'>
															{!tabsAnalytic
																? parseFloat(row[1]).toFixed(2)
																: row[1]}
														</TableCell>
													</TableRow>
												);
											})}
											<TableRow>
												<TableCell colSpan={2} align='right'>
													<b>{!tabsAnalytic ? dataBar.amtA : dataBar.qtyA}</b>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</Box>
				</>
			) : (
				<Toolbar className={cls.toolbar}>
					<Typography className={cls.title} variant='h5' id='tableTitle'>
						<span>{t("Нет данных для статистики")}</span>
					</Typography>
				</Toolbar>
			)}

			{isMultiAcquirer && (
				<>
					<Box p={3} pb={1} textAlign='end'>
						<ButtonStyle
							onClick={() => {
								dispatchOrderList();
								setVisibleState(true);
							}}
						>
							{t("Настроить выбор банков эквайеров")}
						</ButtonStyle>
					</Box>
					<Modal
						open={isVisible}
						close={() => setVisibleState(false)}
						maxWidth='md'
					>
						<ModalContentOrderList />
					</Modal>
				</>
			)}

			<Box p={2}>
				<ButtonStyle
					styleVariable='outlined'
					fullWidth
					component='a'
					href='https://zpos.by/blog/chto-takoe-multiekvajring-i-dlya-chego-ego-primenyayut'
					target='_blank'
					rel='noopener noreferrer'
				>
					{t("Подробнее об мультиэквайринге")}
				</ButtonStyle>
			</Box>
		</Paper>
	);
};

export default Analytic;
