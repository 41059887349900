import * as React from "react";

export function useHandleRequestSort(startParams) {
	const [orders, setState] = React.useState(
		startParams
	);

	const handleRequestSort = (event, property) => {
		const { orderBy, order } = orders;
		const isAsc = orderBy === property && order === 'asc';
		setState({
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		})
	};

	return [orders, handleRequestSort];
}