const validate = (values) => {
	const errors = {};
	if (!values.login) {
		errors.login = "Введите логин";
	}
	if (values.login) {
		if (values.login.split(" ").length - 1) {
			errors.login = "пробелы не допустимы в логине";
		}
	}
	return errors;
};

export default validate;
