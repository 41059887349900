import React, { useEffect, useState } from "react";
import {
	TableRow,
	TableCell,
	Select,
	MenuItem,
	Tooltip,
	IconButton,
} from "@material-ui/core";
import cls from "./tableRowMobile.module.scss";
import CheckboxStyle from "../../../CheckboxStyle";
import statusIconGenerator from "../../../../helpers/statusIconGenerator";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EmailIcon from "@material-ui/icons/Email";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TableRowMobile = ({
	checkboxnone,
	checkbox,
	headCells,
	handleClick,
	row,
	isItemSelected,
	index,
	style,
	column,
	minimize,
	className,
	tariff,
	handleTariff,
	rowIndex,
	unbind,
	email,
	handleClickEmail,
	batchList,
}) => {
	const { t } = useTranslation();
	const [newHeadCells, setNewHeadCells] = useState(headCells);
	const swap = (a, b) => {
		const copy = [...newHeadCells];
		copy[a] = copy.splice(b, 1, copy[a])[0];
		setNewHeadCells(copy);
	};
	useEffect(() => {
		if (headCells.find((el) => el.id === "servStatus")) {
			swap(0, 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<TableRow
			style={style}
			onClick={(event) => handleClick && handleClick(event, row)}
			className={`${cls.mobile_version_table} ${
				unbind ? cls.desktop_version_table_unbind : ""
			} ${isItemSelected ? cls.mobile_version_table_selected : ""}`}
		>
			<TableCell
				colSpan={7}
				style={Object.keys(style).length ? { borderBottom: "none" } : null}
				className={cls.mobile_card_wrapper}
			>
				{newHeadCells.map((el, elIndex) => {
					let rowValue = row[el.id];

					if (el.id === "bnk" || el.id === "cati") {
						rowValue = (
							<div
								style={{ fontFamily: "inherit" }}
								dangerouslySetInnerHTML={{ __html: rowValue }}
							/>
						);
					}

					if (el.icon) {
						rowValue = statusIconGenerator(row[el.id], null, t);
					}
					if (el.doubleIcon) {
						rowValue = statusIconGenerator(row[el.id], "doubleIcon", t);
					}
					if (el.bool) {
						rowValue === 0 ? (rowValue = t("нет")) : (rowValue = t("да"));
					}
					if (el.orderNum) {
						if (Boolean(row.operType)) {
							rowValue = (
								<Link
									className={cls.link_style}
									to={`/orders?orderNum=${row.orderNum}`}
								>
									{t("Оформлен заказ №")}
									{row.orderNum}
								</Link>
							);
						} else {
							rowValue = t("Пополнение лицевого счёта");
						}
					}
					if (el.iconImg) {
						if (Boolean(row.operType)) {
							rowValue = (
								<div className={cls.block_icon_img}>
									<RemoveCircleOutlineIcon className={cls.minus_icon} />
									{rowValue}
								</div>
							);
						} else {
							rowValue = (
								<div className={cls.block_icon_img}>
									<AddCircleOutlineIcon className={cls.plus_icon} />
									{rowValue}
								</div>
							);
						}
					}
					if (el.prefix) {
						rowValue = `${rowValue.toFixed(2)} ${el.prefix}`;
					}
					if (el.combobox) {
						rowValue = (
							<Select
								key={rowIndex}
								value={tariff}
								onChange={handleTariff}
								className={cls.style_select}
							>
								{el.comboboxData.map((value) => {
									return (
										<MenuItem key={value.tariffId} value={value.tariffId}>
											{value.tariffName}
										</MenuItem>
									);
								})}
							</Select>
						);
					}
					return (
						<div
							key={elIndex}
							className={`${cls.mobile_card_row} ${
								(minimize && elIndex === 0) || el.mobileLine
									? cls.mobile_card_row_inline
									: ""
							} ${column ? cls.mobile_card_row_column : ""}`}
						>
							{minimize && elIndex === 0 ? (
								""
							) : (
								<div className={`${cls.mobile_card_name} ${className}`}>
									{el.label}
									{":"}
								</div>
							)}
							{checkbox && minimize && elIndex === 0 && (
								<CheckboxStyle checked={isItemSelected} />
							)}
							{checkboxnone && minimize && elIndex === 0 && (
								<div
									style={{
										height: 40,
										display: "flex",
										justifyContent: "center",
									}}
								>
									{statusIconGenerator(row.servStatus, "icon", t)}
								</div>
							)}
							{email && elIndex === 0 && (
								<Tooltip
									title={
										batchList
											? t("Отправить на email контрольную ленту")
											: t("Отправить на email копию чека")
									}
								>
									<IconButton aria-label='email' onClick={handleClickEmail}>
										<EmailIcon />
									</IconButton>
								</Tooltip>
							)}
							<div
								className={`${className} ${
									minimize && elIndex === 0
										? cls.mobile_card_row_minimize_text
										: ""
								}`}
							>
								{rowValue ? rowValue : rowValue !== 0 ? "-" : 0}
							</div>
						</div>
					);
				})}
			</TableCell>
		</TableRow>
	);
};

export default TableRowMobile;
