import React from "react";
import cls from "./page404.module.scss";
import { Link } from "react-router-dom";
import HelmetComponent from "../../components/HelmetComponent";
import { useTranslation } from "react-i18next";

const Page404 = () => {
	const { t } = useTranslation();
	return (
		<div className={cls.page404_container}>
			<HelmetComponent
				titlePage={t("Страница не найдена")}
				description={t("Страница не найдена")}
				title={t("Страница не найдена")}
			/>
			<div className={cls.page404_code_and_message_wrapper}>
				<div className={cls.page404_code}>404</div>
				<div className={cls.page404_message}>Not Found</div>
			</div>
			<div className={cls.page404_link}>
				<Link to='/'>{t("перейти на главную")}</Link>
			</div>
		</div>
	);
};

export default Page404;
