import React, { useMemo, lazy, Suspense } from "react";
import cls from "./App.module.scss";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTransition, animated } from "react-spring";
import { Container } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import json from "../package.json";

import Footer from "./pages/Footer";
import Header from "./pages/Header";
import Transactions from "./pages/Transactions";
import InformationUser from "./pages/InformationUser";
import InformBlock from "./components/InformBlock";
import PreOrder from "./pages/PreOrder";
import Page404 from "./pages/Page404";
import Login from "./pages/Login";
import Restore from "./pages/Restore";
import Settings from "./pages/Settings";

const HistoryOrders = lazy(() => import("./pages/HistoryOrders"));
const FAQ = lazy(() => import("./pages/FAQ"));
const Equipment = lazy(() => import("./pages/Equipment"));
const Orders = lazy(() => import("./pages/Orders"));

const App = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { isLoggedIn } = useSelector((state) => state.authentication);

	const localUnp = localStorage.getItem("unp");
	const localCheckUserAuth = localStorage.getItem("Authorization");

	useMemo(() => {
		if (localCheckUserAuth && !isLoggedIn) {
			dispatch({ type: "USER_IS_LOGGED_IN" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const transitions = useTransition(location, (location) => location.pathname, {
		from: {
			opacity: 0,
			position: "absolute",
			width: "90%",
			left: "50vw",
		},
		enter: {
			position: "inherit",
			opacity: 1,
			width: "100%",
			left: "0vw",
		},
		leave: {
			position: "absolute",
			opacity: 0,
			width: "90%",
			left: "-50vw",
		},
		unique: false,
		reset: true,
		// config: { duration: 300 }
	});

	if (!isLoggedIn) {
		return (
			<div className={cls.App}>
				<Switch>
					<Route path='/restore' component={Restore} />
					<Route path='*' component={Login} />
				</Switch>
				<div className={cls.version}>v. {json.version}</div>
			</div>
		);
	}

	return (
		<div
			className={`${cls.App} ${
				location.pathname === "/equipment" ||
				location.pathname === "/orders" ||
				location.pathname === "/preorder"
					? cls.App_overflow
					: ""
			}`}
		>
			<Header />

			<div className={cls.home_content}>
				<Container
					style={{ position: "relative" }}
					component='main'
					maxWidth='lg'
				>
					{localUnp && <InformBlock />}

					<Suspense
						fallback={
							<Skeleton
								animation='wave'
								variant='rect'
								style={{ marginTop: 20, height: "400px !important" }}
							/>
						}
					>
						{transitions.map(({ item, props: transition, key }) => (
							<animated.div id='animated' key={key} style={transition}>
								{localUnp && (
									<Switch location={item}>
										<Route exact path='/' component={InformationUser} />
										<Route exact path='/FAQ' component={FAQ} />
										<Route
											exact
											path='/transactions'
											component={Transactions}
										/>
										<Route exact path='/equipment' component={Equipment} />
										<Route exact path='/preorder' component={PreOrder} />
										<Route exact path='/orders' component={Orders} />
										<Route exact path='/history' component={HistoryOrders} />
										<Route exact path='/settings' component={Settings} />
										<Route exact component={Page404} />
									</Switch>
								)}
							</animated.div>
						))}
					</Suspense>
				</Container>
			</div>

			<Footer />
		</div>
	);
};

export default App;
