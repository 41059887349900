import React from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

const InputForm = ({
	handleChange,
	handleBlur,
	values,
	name,
	autoComplete,
	autoFocus,
	label,
	touched,
	errors,
	type,
	disabled,
	helperText,
	fullWidth,
}) => {
	return (
		<TextField
			variant='outlined'
			margin='normal'
			size='small'
			required
			fullWidth={fullWidth}
			label={label}
			name={name}
			autoComplete={autoComplete}
			autoFocus={!!autoFocus}
			onChange={handleChange}
			onBlur={handleBlur}
			value={values ? values[name] : ""}
			error={touched && touched[name] && errors && errors[name] && true}
			helperText={
				(touched && touched[name] && errors && errors[name]) || helperText
			}
			type={type}
			disabled={disabled}
		/>
	);
};

InputForm.propTypes = {
	handleChange: PropTypes.func,
	handleBlur: PropTypes.func,
	values: PropTypes.shape({
		name: PropTypes.string,
	}),
	errors: PropTypes.shape({
		name: PropTypes.string,
	}),
	touched: PropTypes.shape({
		name: PropTypes.string,
	}),
	name: PropTypes.string,
	autoComplete: PropTypes.string,
	autoFocus: PropTypes.bool,
	label: PropTypes.string,
	type: PropTypes.string,
	disabled: PropTypes.bool,
};

InputForm.defaultProps = {
	name: "",
	autoComplete: "",
	autoFocus: false,
	label: "",
	type: "text",
	disabled: false,
	fullWidth: false,
};

export default InputForm;
