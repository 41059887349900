import React from "react";
import cls from "./inform.module.scss";
import { useSelector } from "react-redux";
import { Card } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InformBlock = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const info = useSelector((state) => state.authentication.user);

	return (
		<Card className={cls.info_container_wrapper}>
			{pathname !== "/" && info.isSAAS ? (
				<>
					<div className={cls.block_wrapper_info}>
						<div className={cls.info_item_content_start}>
							{t("Баланс")}:{" "}
							<span>
								{info.accBalance ? info.accBalance.toFixed(2) : "0.00"}
							</span>{" "}
							{t("руб")}
						</div>
						<div
							className={info.isSAAS && cls.info_item_saas}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<div>
								{t("Номер лицевого счёта")}:{" "}
								{" " + info.agrNum ? info.agrNum : ""}
							</div>
							<div>
								{t("УНП")}: {" " + info.unp ? info.unp : ""}
							</div>
						</div>
					</div>
					<div className={cls.info_item_content}>
						<li className={cls.org_short_name}> {info?.orgShortName}</li>
						<div className={cls.info_item_color}> {info?.personName}</div>
					</div>
				</>
			) : (
				<div className={cls.info_item_content_home}>
					<div
						className={info.isSAAS && cls.info_item_saas}
						style={{ display: "flex", flexDirection: "column" }}
					>
						{info.isSAAS ? (
							<>
								<div>
									{t("Номер лицевого счёта")}:{" "}
									{" " + info.agrNum ? info.agrNum : ""}
								</div>
								<div>
									{t("УНП")}: {" " + info.unp ? info.unp : ""}
								</div>
							</>
						) : (
							pathname === "/" && (
								<>
									<div>
										<strong>Операции с банковскими карточками</strong>
									</div>
									<div>Отчет и статистика по Вашим операциям</div>
								</>
							)
						)}
					</div>
					<div>
						{" "}
						{info.personName
							? `${t("Добрый день")}, ${info?.personName}!`
							: `${t("Добрый день")}!`}
					</div>
				</div>
			)}
			{/* <Link to='/settings' title={t('настройка')} className={cls.info_settings_link}>
                <SettingsIcon fontSize="small" className={cls.info_settings} />
            </Link> */}
		</Card>
	);
};
export default InformBlock;
