import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Api from "./helpers/api";
import Store from "./redux/Store";
import "./locales";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";

const api = new Api();

// Grab the state from a global variable injected into the server-generated HTML
// eslint-disable-next-line no-underscore-dangle
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
// eslint-disable-next-line no-underscore-dangle
delete window.__PRELOADED_STATE__;

const cookies = new Cookies();

const store = Store(
	{
		api: api.api,
		cookies,
	},
	preloadedState
);

api.setStore(store);

const rootElement = document.getElementById("root");

let method = "render";
ReactDOM[method](
	<Provider store={store}>
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<HelmetProvider>
				<ToastContainer />
				<App />
			</HelmetProvider>
		</BrowserRouter>
	</Provider>,
	rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
