import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Fab, Tooltip } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const HintComponent = ({ children }) => {
    const [open, setOpen] = useState(false);
    return (
        <Tooltip arrow placement="top" title={children} interactive open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
            <Fab
                // onmouseover="this.style.backgroundColor='white';"
                // onmouseout="this.style.backgroundColor='#333';"
                style={{
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    color: 'silver',
                    marginLeft: 10,
                    width: 20,
                    height: 20,
                    minHeight: 20,
                    marginBottom: 4,
                }}
                size="small"
                aria-label="like"
                onClick={() => setOpen(!open)}
            >
                <HelpOutlineOutlinedIcon />
            </Fab>
        </Tooltip>
    )
}

HintComponent.propTypes = {
    children: PropTypes.string,
};

HintComponent.defaultProps = {
    children: ''
};

export default HintComponent;