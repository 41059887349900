import { combineReducers } from 'redux';
import authentication from './authentication';
import services from './services';
import orders from './orders';
import devices from './devices';
import historyReducer from './historyReducer';
import styleChange from './styleChange';
import transactions from './transactions';
import settings from './settings';

export default combineReducers({
  authentication,
  services,
  orders,
  devices,
  historyReducer,
  styleChange,
  transactions,
  settings
});
