import React from "react";
import PropTypes from "prop-types";
import {
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Select,
	MenuItem,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import cls from "./enhancedTableHead.module.scss";
import HintComponent from "../HintComponent";

const EnhancedTableHead = ({
	order,
	orderBy,
	onRequestSort,
	headCells,
	align,
}) => {
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead>
			<TableRow>
				<TableCell
					colSpan={7}
					className={cls.head_table_select}
				>
					<Select
						value={orderBy}
						className={cls.select_visable}
						onChange={(el) => {
							createSortHandler(el.target.value)();
						}}
						style={{ width: "100%" }}
					>
						{headCells.map((headCell) => (
							<MenuItem
								key={headCell}
								value={headCell.id}
							>
								<div
									style={{ width: "100%" }}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id && (
										<>
											{order === "asc" ? (
												<ArrowUpwardIcon
													color='action'
													fontSize='small'
												/>
											) : (
												<ArrowUpwardIcon
													style={{ transform: "rotate(180deg)" }}
													color='action'
													fontSize='small'
												/>
											)}
										</>
									)}
								</div>
							</MenuItem>
						))}
					</Select>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={align ? align : "center"}
						padding={"normal"}
						className={cls.head_table_row}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.hint ? (
								<div style={{ display: "flex" }}>
									{headCell.label}
									<HintComponent />
								</div>
							) : (
								headCell.label
							)}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
