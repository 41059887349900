import React from "react";
import cls from "./modalContent.module.scss";
import { useTranslation } from "react-i18next";

const ModalContentDel = ({ login, fio }) => {
	const { t } = useTranslation();

	return (
		<div className={cls.modal_content_wrapper}>
			<div className={cls.modal_content_main}>
				<h2>{t("Удалить данного пользователя?")}</h2>
				<h3>
					{t("Логин")}: {login}
					<br />
					{t("ФИО")}: {fio}
				</h3>
			</div>
		</div>
	);
};

export default ModalContentDel;
