import React from "react";
import { Toolbar, Typography } from "@material-ui/core";
import cls from "./style.module.scss";
import { useTranslation } from "react-i18next";

const EnhancedTableToolbar = ({ params }) => {
	const { t } = useTranslation();
	return (
		<Toolbar className={cls.prepare_order_pay_table_toolbar}>
			<Typography
				className={cls.prepare_order_pay_table_title}
				variant='h5'
				component='h2'
				id='tableTitle'
			>
				<span>{t("Новый заказ")}</span>
			</Typography>

			<Typography
				className={cls.prepare_order_pay_table_title}
				variant='h6'
				component='h3'
				id='tableTitle'
			>
				<div>
					{`
					${t("Период действия заказа с")}
					${params.beginDate}
					${t("по")}
					${params.endDate}
				`}
				</div>
			</Typography>
		</Toolbar>
	);
};

export default EnhancedTableToolbar;
