import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Toolbar,
	Typography,
	IconButton,
} from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useTranslation } from "react-i18next";

import transactions from "../../../../redux/actions/transactions";

import cls from "../../transactions.module.scss";
import "./TranList.module.scss";

import SortHelper from "../../../../helpers/sortHelper";
import { useHandleRequestSort } from "../../../../helpers/handleRequestSort";

import EnhancedTableHead from "../EnhancedTableHead";
import EnhancedTableToolbar from "../EnhancedTableToolbar";
import HandlerRowDesktopAndMobile from "../../../../components/HandlerRowDesktopAndMobile";
import Loading from "../../../../components/Loading";
import ModalFilter from "../../../../components/ModalFilter";
import EnhancedTablePagination from "../../../../components/EnhancedTablePagination";

const TranList = ({ loading, rows, params, rowsCount, rowsTotal, title }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const headCells = [
		{
			id: "sn",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Терминал"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "bnk",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Банк-эквайер"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "trnDt",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Дата-Время"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "chqN",
			numeric: true,
			disablePadding: true,
			align: "left",
			label: t("№ чека"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "pan",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Карта"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "trnAmt",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Сумма"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "trnType",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Операция"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "resC",
			numeric: true,
			disablePadding: true,
			align: "left",
			label: t("Код результата"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "resD",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Описание результата"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "rrn",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("RRN"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "auth",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Код авторизации"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "emi",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Банк-эмитент"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
	];

	const [{ order, orderBy }, handleRequestSort] = useHandleRequestSort({
		order: "desc",
		orderBy: "trnDt",
	});

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(rowsCount);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const [filterOpen, setFilterOpen] = useState(false);
	const [filterActive, setFilterActive] = useState({});

	const handleChangeFilterRows = (array, params) => {
		return array.filter((el) => {
			if (Object.keys(params).length) {
				let status = [];
				Object.keys(params).forEach((key) => {
					if (el[key] === params[key]) {
						status.push(true);
					} else {
						let statusEl = false;
						if (Array.isArray(params[key])) {
							params[key].forEach((parEl) => {
								if (Boolean(el[key].find((elEl) => elEl === parEl))) {
									statusEl = true;
								}
							});
						}
						statusEl ? status.push(true) : status.push(false);
					}
				});
				let finishSearch = false;
				if (status.find((bool) => bool === false) === undefined) {
					finishSearch = true;
				}
				return finishSearch;
			}
			return true;
		});
	};

	const [newRows, setNewRows] = useState(
		handleChangeFilterRows(rows, filterActive)
	);

	useEffect(() => {
		setNewRows(handleChangeFilterRows(rows, filterActive));
	}, [filterActive, rows]);

	useEffect(() => {
		if (rowsPerPage !== rowsCount)
			dispatch(
				transactions.tranList({
					...params,
					page: 1,
					rowCount: rowsPerPage,
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowsPerPage]);

	function TablePaginationActions(props) {
		const { count, page, rowsPerPage, onPageChange } = props;

		const prevPage = page - 1;
		const nextPage = page + 1;

		const handleBackButtonClick = (event) => {
			onPageChange(event, prevPage);
			dispatch(
				transactions.tranList({
					...params,
					page: nextPage - 1,
					rowCount: rowsPerPage,
				})
			);
		};

		const handleNextButtonClick = (event) => {
			onPageChange(event, nextPage);
			dispatch(
				transactions.tranList({
					...params,
					page: nextPage + 1,
					rowCount: rowsPerPage,
				})
			);
		};

		return (
			<>
				<IconButton
					onClick={handleBackButtonClick}
					disabled={page === 0}
					aria-label='previous page'
				>
					<KeyboardArrowLeft />
				</IconButton>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label='next page'
				>
					<KeyboardArrowRight />
				</IconButton>
			</>
		);
	}

	return (
		<Paper className={cls.paper}>
			{rows?.length ? (
				<>
					<EnhancedTableToolbar
						filterActive={filterActive}
						setFilterActive={setFilterActive}
						setFilterOpen={() => setFilterOpen(!filterOpen)}
						title={title}
					/>
					<TableContainer className={cls.container_wrapper}>
						<Loading load={loading} />
						<Table
							className={`${cls.table_container} ${cls.minimize_padding}`}
							aria-labelledby='tableTitle'
							size='medium'
							aria-label='enhanced table'
						>
							<EnhancedTableHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								headCells={headCells}
								sort
							/>
							<ModalFilter
								setFilterOpen={() => setFilterOpen(false)}
								filterOpen={filterOpen}
								setFilterActive={setFilterActive}
								headCells={headCells}
								rows={rows}
								filterActive={filterActive}
								filterRows={newRows}
							/>
							<TableBody>
								{SortHelper(newRows, order, orderBy).map((row, index) => {
									return (
										<Fragment key={index}>
											<HandlerRowDesktopAndMobile
												headCells={headCells}
												row={row}
												cursor
												column
												index={row.id}
												minimize
											/>
										</Fragment>
									);
								})}
								{!newRows.length && rows.length && (
									<TableRow style={{ height: 53 * 1 }}>
										<TableCell colSpan={headCells.length}>
											<div className={cls.filter_empty_text}>
												{t(
													"По вашему фильтру ничего не найдено, выберите другие настройки"
												)}
												<div
													className={cls.reset_filter}
													onClick={() => setFilterActive({})}
												>
													{t("сбросить фильтр")}
												</div>
											</div>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<EnhancedTablePagination
						count={rowsTotal}
						page={page > Math.ceil(rowsTotal / rowsPerPage) - 1 ? 0 : page}
						rowsPerPage={rowsPerPage}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[25, 50, 100]}
						ActionsComponent={TablePaginationActions}
					/>
				</>
			) : (
				<Toolbar className={cls.toolbar}>
					<Typography
						className={cls.title}
						variant='h5'
						id='tranTitle'
						align='center'
					>
						<span>{t("Нет данных по транзакциям")}</span>
					</Typography>
				</Toolbar>
			)}
		</Paper>
	);
};

export default TranList;
