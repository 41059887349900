import React from "react";
import PropTypes from "prop-types";
import {
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Select,
	MenuItem,
} from "@material-ui/core";
import cls from "../../transactions.module.scss";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CheckboxStyle from "../../../../components/CheckboxStyle";

const EnhancedTableHead = ({
	minimize,
	sort,
	checkbox,
	email,
	onSelectAllClick,
	order,
	orderBy,
	numSelected,
	rowCount,
	onRequestSort,
	headCells,
}) => {
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead>
			<TableRow
				className={`${cls.head_table_wrapper} ${
					minimize ? cls.minimize_padding : ""
				}`}
			>
				{email && <TableCell className={cls.head_table_email}></TableCell>}

				{checkbox && (
					<TableCell className={cls.head_table_checkbox}>
						<CheckboxStyle
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
						/>
						{sort && (
							<Select
								value={orderBy}
								className={cls.select_visable}
								onChange={(el) => createSortHandler(el.target.value)()}
							>
								{headCells.map((headCell) => (
									<MenuItem key={headCell} value={headCell.id}>
										<div onClick={createSortHandler(headCell.id)}>
											{headCell.label}
											{orderBy === headCell.id && headCell.sort && (
												<>
													{order === "asc" ? (
														<ArrowUpwardIcon color='action' fontSize='small' />
													) : (
														<ArrowUpwardIcon
															style={{ transform: "rotate(180deg)" }}
															color='action'
															fontSize='small'
														/>
													)}
												</>
											)}
										</div>
									</MenuItem>
								))}
							</Select>
						)}
					</TableCell>
				)}
				{headCells.map((headCell) => (
					<TableCell
						align={minimize ? "center" : "center"}
						key={headCell.id}
						onClick={() => headCell.sort && createSortHandler(headCell.id)()}
						className={`${cls.head_table_row_align} ${
							headCell.disablePadding
								? cls.head_table_row_none_padding
								: cls.head_table_row_padding
						}`}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{headCell.sort ? (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
							>
								{headCell.label}
							</TableSortLabel>
						) : (
							<>{headCell.label}</>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number,
};
EnhancedTableHead.defaultProps = {
	sort: false,
	checkbox: false,
	minimize: false,
	onSelectAllClick: () => {},
	order: "asc",
	orderBy: "equType",
	onRequestSort: () => {},
};

export default EnhancedTableHead;
