import React from "react";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import cls from "./style.module.scss";
import HintComponent from "../../../HintComponent";

const EnhancedTableHead = ({ headCells }) => {
	return (
		<TableHead className={cls.table_head_wrapper}>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align='center'
					>
						{headCell.hint ? (
							<div style={{ display: "flex" }}>
								{headCell.label}
								<HintComponent />
							</div>
						) : (
							headCell.label
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default EnhancedTableHead;
