import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import cls from "./transactions.module.scss";

import transactions from "../../redux/actions/transactions";

import HelmetComponent from "../../components/HelmetComponent";
import DevicesList from "./component/DevicesList";

const Transactions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { isRequested, devices, devicesLength } = useSelector(
		(state) => state.transactions
	);

	const [tabsValue, setTabsValue] = useState(0);
	const tabsChange = (newValue) => {
		setTabsValue(newValue);
	};

	useEffect(() => {
		dispatch(transactions.deviceList({ page: 1 }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			dispatch(transactions.resetTransactions());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={cls.container_wrapper}>
			<HelmetComponent
				titlePage={t("Операции с банковскими карточками")}
				description={t("Операции с банковскими карточками")}
				title={t("Операции с банковскими карточками")}
			/>

			<DevicesList
				loading={isRequested}
				rows={devices}
				rowsLength={devicesLength}
				tabsValue={tabsValue}
				tabsChange={tabsChange}
				dispatchTranList={(params, callback) => {
					dispatch(
						transactions.tranList(params, (data) => callback && callback(data))
					);
				}}
				dispatchBatchList={(params, callback) => {
					dispatch(
						transactions.batchList(params, (data) => callback && callback(data))
					);
				}}
				dispatchAnalyticEmi={(params, callback) => {
					dispatch(
						transactions.analyticEmi(
							params,
							(data) => callback && callback(data)
						)
					);
				}}
			/>
		</div>
	);
};

export default Transactions;
