import types from './../Types';
import axios from 'axios';
import orders from './orders';
import { toast } from 'react-toastify';
const { CancelToken } = axios;
let wjCancelOrderPay;

const preOrder = (callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.PRE_ORDER_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/get/preorder'
    }).then(
      ({ data: { orderData, orderParams } }) => {
        if (!orderData.length) {
          callback && callback(false);
          return
        }
        let newEquipments = [];
        const status = { pre: 1, ok: 2, warn: 3, new: 4, cancel: 5 }
        orderData.forEach((item) => {
          if (!newEquipments.find(el => el?.serialNumber === item.serialNumber)?.serialNumber) {
            const checkSerialNumber = orderData.filter(el => el.serialNumber === item.serialNumber && Boolean(el.servMandatory)).length;
            newEquipments.push({
              serialNumber: item.serialNumber,
              // servStatus: Boolean(item.servMandatory) ? item.servStatus : Boolean(checkSerialNumber) ? 'pre' : 'new',
              servStatus: item.servStatus ? item.servStatus : Boolean(checkSerialNumber) ? 'pre' : 'new',
              equType: item.equType,
              // servStatusAll: [Boolean(item.servMandatory) ? item.servStatus : Boolean(checkSerialNumber) ? 'pre' : 'new'],
              servStatusAll: [item.servStatus ? item.servStatus : Boolean(checkSerialNumber) ? 'pre' : 'new'],
              items: [item],
              sbName: item.sbName
            });
          } else {
            const findItem = newEquipments.find(el => el?.serialNumber === item.serialNumber);
            // if (!findItem.servStatusAll.filter(el => el === item.servStatus).length && Boolean(item.servMandatory)) {
            //   findItem.servStatusAll.push(item.servStatus);
            // }
            if ((status[findItem.servStatus] < status[item.servStatus]) && Boolean(item.servMandatory)) {
              findItem.servStatus = item.servStatus
              findItem.servStatusAll = [item.servStatus]
            }
            findItem.items.push(item);
          }
        });
        dispatch({ type: types.PRE_ORDER_REQUEST_SUCCESS, preOrder: newEquipments, preOrderLength: orderData.length, preOrderParams: orderParams });
        callback && callback(true);
      },
      ({ response }) => {
        dispatch({ type: types.PRE_ORDER_REQUEST_ERROR, error: response });
        callback && callback(false);
      },
    );
  };
}

const myServices = (callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.SERVICES_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/my/devices/full/tariff'
    }).then(
      (data) => {
        let newEquipments = [];
        const status = { pre: 1, ok: 2, warn: 3, new: 4, cancel: 5 }
        data.data.forEach((item) => {
          if (!newEquipments.find(el => el?.serialNumber === item.serialNumber)?.serialNumber) {
            const checkSerialNumber = data.data.filter(el => el.serialNumber === item.serialNumber && Boolean(el.servMandatory)).length;
            newEquipments.push(
              {
                aStateDT: item.aStateDT,
                serialNumber: item.serialNumber,
                // servStatus: Boolean(item.servMandatory) ? item.servStatus : Boolean(checkSerialNumber) ? 'pre' : 'new',
                servStatus: item.servStatus ? item.servStatus : Boolean(checkSerialNumber) ? 'pre' : 'new',
                equType: item.equType,
                // servStatusAll: [Boolean(item.servMandatory) ? item.servStatus : Boolean(checkSerialNumber) ? 'pre' : 'new'],
                servStatusAll: [item.servStatus ? item.servStatus : Boolean(checkSerialNumber) ? 'pre' : 'new'],
                items: [item],
                sbName: item.sbName,
                equCode: item.equCode
              }
            );
          } else {
            const findItem = newEquipments.find(el => el?.serialNumber === item.serialNumber);
            // if (!findItem.servStatusAll.filter(el => el === item.servStatus).length && Boolean(item.servMandatory)) {
            //   findItem.servStatusAll.push(item.servStatus);
            // }
            if ((status[findItem.servStatus] < status[item.servStatus]) && Boolean(item.servMandatory)) {
              findItem.servStatus = item.servStatus
              findItem.servStatusAll = [item.servStatus]
            }
            findItem.items.push(item);
          }
        });
        dispatch({ type: types.SERVICES_REQUEST_SUCCESS, equipments: newEquipments, equipmentsLength: data.data.length });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.SERVICES_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

const prepareOrderPay = (params, orderId, callback) => {
  return (dispatch, getState, { api }) => {

    dispatch({ type: types.PREPARE_AN_ORDER_PAY_REQUEST });
    // params.orderData = getState().services.equipmentsActive;
    wjCancelOrderPay && wjCancelOrderPay();

    return api({
      method: 'POST',
      url: `/api/cabinet/new/order/details${orderId ? `/${orderId}` : ''}`,
      data: params,
      cancelToken: new CancelToken(function executor(c) {
        wjCancelOrderPay = c;
      }),
    }).then(
      (data) => {
        dispatch({ type: types.PREPARE_AN_ORDER_PAY_REQUEST_SUCCESS, prepareOrderPay: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.PREPARE_AN_ORDER_PAY_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

const orderPay = (params, orderId, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.ORDER_PAY_REQUEST });
    // if (!orderId) params.orderData = getState().services.equipmentsActive;
    return api({
      method: 'POST',
      url: `/api/cabinet/new/order/pay${orderId ? `/${orderId}` : ''}`,
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.ORDER_PAY_REQUEST_SUCCESS, orderPay: data.data });
        toast.success('Оплата прошла успешно');
        callback && callback();
      },
      ({ response }) => {
        dispatch({ type: types.ORDER_PAY_REQUEST_ERROR, error: response });
      },
    );
  };
}

const currentOrderUpdate = (params, orderId, orderNum, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.ORDER_UPDATE_REQUEST });
    return api({
      method: 'POST',
      url: `/api/cabinet/my/order/update/${orderId}`,
      data: params
    }).then(
      (data) => {
        if (Boolean(data?.data?.error)) {
          toast.error(`Имеются пересечения`);
          callback && callback();
        } else {
          toast.success(`Заказ № ${orderNum} изменен`);
        }
        dispatch(orders.myOrders());
        dispatch({ type: types.ORDER_UPDATE_REQUEST_SUCCESS, orderUpdate: data.data });
      },
      ({ response }) => {
        dispatch({ type: types.ORDER_UPDATE_REQUEST_ERROR, error: response });
      },
    );
  };
}

// const serviceList = (callback) => {
//   return (dispatch, getState, { api }) => {
//     dispatch({ type: types.NEW_SERVICE_LIST_REQUEST });
//     return api({
//       method: 'POST',
//       url: `/api/cabinet/new/service/list`
//     }).then(
//       (data) => {
//         dispatch({ type: types.NEW_SERVICE_LIST_SUCCESS, serviceList: data.data });
//         callback && callback();
//       },
//       ({ response }) => {
//         dispatch({ type: types.NEW_SERVICE_LIST_ERROR, error: response });
//         callback && callback(response);
//       },
//     );
//   };
// }

// const serviceDevices = (params, callback) => {
//   return (dispatch, getState, { api }) => {
//     dispatch({ type: types.NEW_SERVICE_DEVICES_REQUEST });
//     return api({
//       method: 'POST',
//       url: `/api/cabinet/new/service/devices`,
//       data: params
//     }).then(
//       (data) => {
//         dispatch({ type: types.NEW_SERVICE_DEVICES_SUCCESS, serviceDevices: data.data });
//         callback && callback();
//       },
//       ({ response }) => {
//         dispatch({ type: types.NEW_SERVICE_DEVICES_ERROR, error: response });
//         callback && callback(response);
//       },
//     );
//   };
// }

const resetOrder = (callback) => {
  callback && callback();
  return (dispatch) => {
    dispatch({ type: types.PREPARE_AN_ORDER_PAY_REQUEST_SUCCESS, prepareOrderPay: {} });
    dispatch({ type: types.NEW_SERVICE_DEVICES_SUCCESS, serviceDevices: [] });
  };
}

const resetOrderPay = (callback) => {
  callback && callback();
  return (dispatch) => {
    dispatch({ type: types.PREPARE_AN_ORDER_PAY_REQUEST_SUCCESS, prepareOrderPay: {} });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  myServices,
  prepareOrderPay,
  orderPay,
  resetOrder,
  resetOrderPay,
  currentOrderUpdate,
  // serviceList,
  // serviceDevices,
  preOrder
};
