import React from 'react';
import PropTypes from 'prop-types';
import cls from './checkbox.module.scss';
import { Checkbox } from '@material-ui/core';

const CheckboxStyle = ({ style,indeterminate, checked, onChange,disabled,name }) => {

    return (
        <Checkbox
            indeterminate={indeterminate}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            name={name}
            style={style}
            inputProps={{ 'aria-label': 'select all desserts' }}
            className={checked ? cls.checkbox_default_active : ''}
        />
    )
}

CheckboxStyle.propTypes = {
    onChange: PropTypes.func,
    indeterminate: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string
};

CheckboxStyle.defaultProps = {
    indeterminate: false,
    checked: false,
    disabled: false,
    name:'',
    onChange: () => { },
    style:null
};

export default CheckboxStyle;