import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TableRowDesktop from "./components/TableRowDesktop";
import TableRowMobile from "./components/TableRowMobile";

const HandlerRowDesktopAndMobile = ({
	paddingCheck,
	checkboxnone,
	cursor,
	handleClickName,
	handleClickCheckBox,
	checkbox,
	headCells,
	handleClick,
	row,
	isItemSelected,
	index,
	style,
	column,
	minimize,
	className,
	tariff,
	handleTariff,
	unbind,
	email,
	emailnone,
	handleClickEmail,
	batchList,
}) => {
	return (
		<Fragment>
			<TableRowDesktop
				headCells={headCells}
				row={row}
				checkbox={checkbox}
				index={index}
				handleClick={handleClick}
				isItemSelected={isItemSelected}
				style={style}
				cursor={cursor}
				className={className}
				handleClickCheckBox={handleClickCheckBox}
				handleClickName={handleClickName}
				checkboxnone={checkboxnone}
				paddingCheck={paddingCheck}
				tariff={tariff}
				handleTariff={handleTariff}
				rowIndex={index}
				unbind={unbind}
				email={email}
				emailnone={emailnone}
				handleClickEmail={handleClickEmail}
				batchList={batchList}
			/>
			<TableRowMobile
				headCells={headCells}
				row={row}
				column={column}
				checkbox={checkbox}
				index={index}
				handleClick={handleClick}
				isItemSelected={isItemSelected}
				style={style}
				minimize={minimize}
				className={className}
				checkboxnone={checkboxnone}
				tariff={tariff}
				handleTariff={handleTariff}
				rowIndex={index}
				unbind={unbind}
				email={email}
				emailnone={emailnone}
				handleClickEmail={handleClickEmail}
				batchList={batchList}
			/>
		</Fragment>
	);
};

HandlerRowDesktopAndMobile.propTypes = {
	checkbox: PropTypes.bool,
	column: PropTypes.bool,
	style: PropTypes.shape({}),
	handleClick: PropTypes.func,
	headCells: PropTypes.array.isRequired,
	row: PropTypes.shape({}).isRequired,
	index: PropTypes.number,
	isItemSelected: PropTypes.bool,
	minimize: PropTypes.bool,
	cursor: PropTypes.bool,
	handleTariff: PropTypes.func,
	rowIndex: PropTypes.number,
	unbind: PropTypes.bool,
	email: PropTypes.bool,
	emailnone: PropTypes.bool,
	handleClickEmail: PropTypes.func,
	batchList: PropTypes.bool,
};

HandlerRowDesktopAndMobile.defaultProps = {
	checkbox: false,
	column: false,
	cursor: false,
	style: {},
	handleClick: () => {},
	handleClickCheckBox: () => {},
	handleClickName: () => {},
	isItemSelected: false,
	minimize: false,
	className: "",
	checkboxnone: false,
	paddingCheck: false,
	handleTariff: () => {},
	rowIndex: 0,
	unbind: true,
	email: false,
	emailnone: false,
	handleClickEmail: () => {},
	batchList: false,
};

export default HandlerRowDesktopAndMobile;
