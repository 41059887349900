import types from './../Types'

const deviceList = (params, callback) => { // запрос списка терминалов
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/acq/device/list',
      data: params,
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_DEVICES_REQUEST_SUCCESS, devices: data.data, devicesLength: data.data.length });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}

const tranList = (params, callback) => { // отчет по транзакциям
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/acq/tran/list',
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_LIST_REQUEST_SUCCESS, tranList: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}

const batchList = (params, callback) => { // отчет по бизнес-дню - список бизнес дней
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/acq/batch/list',
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_BATCH_LIST_REQUEST_SUCCESS, batchList: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}

const emailBatch = (params, callback) => { // отправка отчета по бизнес-дню
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/acq/send/email/batch',
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_EMAIL_BATCH_REQUEST_SUCCESS, emailBatch: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}

const batchTran = (params, callback) => { // отчет по бизнес-дню - список транзакций в выбранном бизнес дне
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/acq/batchTran/list',
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_BATCH_TRAN_LIST_REQUEST_SUCCESS, batchTran: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}

const emailBatchTran = (params, callback) => { // отправка отчета по бизнес-дню - список транзакций в выбранном бизнес дне
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/acq/send/email/tran',
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_EMAIL_BATCH_TRAN_REQUEST_SUCCESS, emailBatchTran: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}

const analyticEmi = (params, callback) => { // отчет по бизнес-дню - список транзакций в выбранном бизнес дне
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/analytic/emi',
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_ANALYTIC_REQUEST_SUCCESS, analyticEmi: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}

const orderList = (params, callback) => { // список банков-эквайеров
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/acq/order/list',
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_ORDER_LIST_REQUEST_SUCCESS, orderList: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}
const orderListUp = (params, callback) => { // список банков-эквайеров - повысить приоритет банка
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/acq/order/update/up',
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_ORDER_LIST_UP_REQUEST_SUCCESS, orderListUp: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}
const orderListDown = (params, callback) => { // список банков-эквайеров - понизить приоритет банка
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.TRANSACTIONS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/acq/order/update/down',
      data: params
    }).then(
      (data) => {
        dispatch({ type: types.TRANSACTIONS_ORDER_LIST_DOWN_REQUEST_SUCCESS, orderListDown: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.TRANSACTIONS_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}

const resetTransactions = (callback) => { // сброс всех запросов
  callback && callback();
  return (dispatch) => {
    dispatch({ type: types.TRANSACTIONS_LIST_REQUEST_SUCCESS, tranList: {} });
    dispatch({ type: types.TRANSACTIONS_BATCH_LIST_REQUEST_SUCCESS, batchList: {} });
    dispatch({ type: types.TRANSACTIONS_ANALYTIC_REQUEST_SUCCESS, analyticEmi: {} });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  deviceList,
  tranList,
  batchList,
  emailBatch,
  batchTran,
  emailBatchTran,
  analyticEmi,
  orderList,
  orderListUp,
  orderListDown,
  resetTransactions,
};
