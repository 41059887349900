import axios from "axios";
import Types from "../redux/Types";
import { toast } from "react-toastify";

class Api {
	constructor() {
		// configuration
		this.api = axios.create({
			baseURL: process.env.REACT_APP_SERVER_URL,
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
			},
		});
		let isRefreshing = false;
		let failedQueue = [];

		const processQueue = (error, token = null, toPageLogin = null) => {
			failedQueue.forEach((prom) => {
				if (error) {
					prom.reject(error);
				} else {
					prom.resolve(token);
				}
			});
			if (toPageLogin) {
				toPageLogin();
			}
			failedQueue = [];
		};

		this.api.defaults.withCredentials = true;

		this.api.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				const originalRequest = error.config;
				const { dispatch } = this.store;
				const toPageLogin = () => {
					dispatch({ type: Types.USER_IS_NOT_LOGGED_IN });
					localStorage.removeItem("Authorization");
					localStorage.removeItem("unp");
				};
				if (error.response.status === 500) {
					toast.error('Ой, что-то пошло не так...');
				}
				if (
					error.response.status === 401 &&
					!originalRequest._retry &&
					error.response.config.url !== "/api/auth/login"
				) {
					if (isRefreshing) {
						return new Promise((resolve, reject) => {
							failedQueue.push({ resolve, reject });
						})
							.then((token) => {
								originalRequest.headers["Authorization"] = "Bearer " + token;
								return axios(originalRequest);
							})
							.catch((err) => {
								return Promise.reject(err);
							});
					}
					const token = localStorage.getItem("token");
					const refreshToken = localStorage.getItem("refreshToken");
					if (originalRequest.headers.Authorization !== `Bearer ${token}`) {
						originalRequest.headers["Authorization"] = `Bearer ${token}`;
						return Promise.resolve(this.api(originalRequest));
					}
					originalRequest._retry = true;
					isRefreshing = true;

					return new Promise((resolve, reject) => {
						axios
							.post(
								`${process.env.REACT_APP_SERVER_URL
									? process.env.REACT_APP_SERVER_URL
									: ""
								}/api/auth/refresh`,
								{ refreshToken, token }
							)
							.then(({ data }) => {
								if (!data.token) {
									toPageLogin();
									toast.warn(
										"Ваш сеанс работы с сайтом завершен из-за отсутствия активности"
									);
									return;
								}
								localStorage.setItem("token", data.token);
								localStorage.setItem("refreshToken", data.refreshToken);
								axios.defaults.headers.common[
									"Authorization"
								] = `Bearer ${data.token}`;
								originalRequest.headers[
									"Authorization"
								] = `Bearer ${data.token}`;
								processQueue(null, data.token);
								resolve(axios(originalRequest));
							})
							.catch((err) => {
								processQueue(err, null, toPageLogin);
								toast.warn(
									"Ваш сеанс работы с сайтом завершен из-за отсутствия активности"
								);
								reject(err);
							})
							.then(() => {
								isRefreshing = false;
							});
					});
				}
				return Promise.reject(error);
			}
		);
	}

	listener() {
		const token = localStorage.getItem("token");
		const unp = localStorage.getItem("unp");

		if (
			token &&
			this.api.defaults.headers.common.Authorization !== `Bearer ${token}`
		) {
			this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
		}

		if (unp && this.api.defaults.headers.common["App-Data"] !== unp) {
			this.api.defaults.headers.common["App-Data"] = unp;
		}
	}

	setStore(store) {
		this.store = store;
		this.store.subscribe(this.listener.bind(this));
	}
}

export default Api;
