import React, { Fragment } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Collapse,
    Paper,
} from '@material-ui/core';
import EnhancedTableHead from '../EnhancedTableHead';
import handleClickRow from '../../../../../../helpers/handleClickRow';
import HandlerRowDesktopAndMobile from '../../../../../../components/HandlerRowDesktopAndMobile';
import { useTranslation } from 'react-i18next';


const ItemTableList = ({ cls, row, collapse, newRows, selected, setSelected }) => {
    const { t } = useTranslation();
    const headCells = [
        { id: 'servStatus', numeric: false, disablePadding: true, align: 'left', label: t('Состояние'), bool: false, mobileLine: true, icon: true },
        { id: 'tariffName', numeric: true, disablePadding: false, align: 'left', label: t('Тариф'), bool: false, mobileLine: false },
        { id: 'servDateStop', numeric: true, disablePadding: false, align: 'right', label: t('Действие'), bool: false, mobileLine: true },
    ];
    
    const isSelected = name => selected.indexOf(name) !== -1;
    return (
        <TableRow>
            <TableCell colSpan={4} padding="none">
                <Collapse in={!Boolean(collapse.filter(el => el === row.serialNumber).length)} timeout="auto" unmountOnExit>
                    <Paper className={cls.paper_wrapper}>
                        <Table
                            className={`${cls.equipment_and_services_table_min} ${cls.minimize_padding}`}
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                rowCount={newRows.length}
                                headCells={headCells}
                                minimize
                            />
                            <TableBody>
                                {row.items.map((el, ind) => {
                                    const isItemSelected = isSelected(el);
                                    return (
                                        <Fragment key={`el-${ind}`}>
                                            <HandlerRowDesktopAndMobile
                                                headCells={headCells}
                                                handleClick={(event, row) => handleClickRow(event, row, selected, setSelected)}
                                                row={el}
                                                cursor
                                                checkbox
                                                isItemSelected={isItemSelected}
                                                column
                                                minimize
                                                paddingCheck
                                            />
                                        </Fragment>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}

export default ItemTableList;