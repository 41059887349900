import React from "react";
import { Tabs, Tab } from "@material-ui/core";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const TabsComponenet = ({ onChange, value, labels, tabClass }) => {
	return (
		<Tabs
			value={value}
			onChange={onChange}
			aria-label='simple tabs'
		>
			{labels.map((label, index) => {
				return (
					<Tab
						className={tabClass}
						key={index}
						label={label}
						{...a11yProps(index)}
					/>
				);
			})}
		</Tabs>
	);
};
export default TabsComponenet;
