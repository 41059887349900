import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { push as Menu } from "react-burger-menu";
import cls from "./header.module.scss";

import services from "../../redux/actions/services";
import authentication from "../../redux/actions/authentication";

import Modal from "../../components/Modal";
import ModalContentSelect from "../../components/Modal/components/ModalContentSelect";
import ModalContentInput from "../../components/Modal/components/ModalContentInput";

import logo from "../../img/zPOS_logo_RGB_color.svg";
import swipeHandler from "./swipeHandler";
import HamburgerMenu from "../../components/HamburgerMenu";
import SettingsIcon from "@material-ui/icons/Settings";

const Header = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const { userOrgList, user, isRequested, isLoggedIn } = useSelector(
		(state) => state.authentication
	);

	const [isVisible, setVisibleState] = useState(true);
	const [unpValue, setUnpValue] = useState(userOrgList[0]?.unp);
	const [unpValueInput, setUnpValueInput] = useState("");
	const [arrayMenu, setArrayMenu] = useState([]);

	const changeSelect = (e) => {
		setUnpValue(e.target.value);
	};
	const changeInput = (e) => {
		setUnpValueInput(e.target.value);
	};

	const localUnp = localStorage.getItem("unp");
	const localCheckUserAuth = localStorage.getItem("Authorization");

	const [buttonBurger, setButtonBurger] = useState(false);
	const [styleLeft, setStyleLeft] = useState({ left: 0, width: 0 });

	useEffect(() => {
		user.isSAAS &&
			setArrayMenu([
				{ id: 1, name: t("Главная"), path: "/" },
				{ id: 2, name: t("Моё оборудование"), path: "/equipment" },
				{ id: 3, name: t("Мои заказы"), path: "/orders" },
				{ id: 4, name: t("Вопрос/ответ"), path: "/faq" },
			]);
		user.isMultiAcquirer &&
			setArrayMenu([
				{ id: 1, name: t("Мои транзакции"), path: "/transactions" },
				{ id: 2, name: t("Вопрос/ответ"), path: "/faq" },
			]);
		user.isSAAS &&
			user.isMultiAcquirer &&
			setArrayMenu([
				{ id: 1, name: t("Главная"), path: "/" },
				{ id: 2, name: t("Мои транзакции"), path: "/transactions" },
				{ id: 3, name: t("Моё оборудование"), path: "/equipment" },
				{ id: 4, name: t("Мои заказы"), path: "/orders" },
				{ id: 5, name: t("Вопрос/ответ"), path: "/faq" },
			]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const arrayMenuExt = [
		{ id: 1, name: t("Тарифы"), path: "https://zpos.by/#tariffs" },
		{ id: 2, name: t("Контакты"), path: "https://zpos.by/#contacts" },
	];

	const handleMouseEnter = (e) => {
		setStyleLeft({ left: e.target.offsetLeft, width: e.target.offsetWidth });
	};

	useEffect(() => {
		swipeHandler(setButtonBurger);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(authentication.orgList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (localUnp && localCheckUserAuth && isLoggedIn) {
			dispatch(
				authentication.userInfo((data) => {
					const userInfo = data.data;

					if (userInfo.isSAAS === 0) {
						if (userInfo.unp === "ECSAT") {
							if (location.pathname === "/") {
								history.push("/settings");
							}
						} else {
							if (location.pathname === "/") {
								history.push("/transactions");
							}
						}
					}
				})
			);
			dispatch(
				services.preOrder((el) => {
					if (el) {
						history.push("/preorder");
					} else {
						if (location.pathname === "/preorder") {
							history.push("/");
						}
					}
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn, localCheckUserAuth, localUnp]);

	const menuContent = (
		<>
			{arrayMenu.map((el) => (
				<div
					key={el.id}
					className={cls.header_tab}
					onMouseEnter={handleMouseEnter}
					onClick={() => setButtonBurger(false)}
				>
					<NavLink
						onClick={() => window.scrollTo({ top: 0 })}
						exact
						className={cls.header_tab_all}
						activeClassName={cls.header_tab_active}
						to={el.path}
					>
						{el.name}
					</NavLink>
				</div>
			))}
			{arrayMenuExt.map((el) => {
				return (
					<div
						key={el.id}
						className={cls.header_tab}
						onMouseEnter={handleMouseEnter}
						onClick={() => setButtonBurger(true)}
					>
						<a
							className={cls.header_tab_all}
							href={el.path}
							target='_blank'
							rel='noopener noreferrer'
						>
							{el.name}
						</a>
					</div>
				);
			})}
			<div className={cls.header_tab} onMouseEnter={handleMouseEnter}>
				<NavLink
					className={cls.header_tab_all_last}
					to='/'
					onClick={() => dispatch(authentication.logout())}
				>
					{t("Выход")}
				</NavLink>
			</div>
		</>
	);

	return (
		<>
			{userOrgList[0]?.roleId === 2 && userOrgList[0]?.unp === "ECSAT" && (
				<Modal
					open={isVisible}
					close={() => dispatch(authentication.logout())}
					titleButton='отправить'
					titleButton2='закрыть'
					buttonClick={() => {
						localStorage.setItem("unp", unpValueInput);
						localStorage.setItem("Authorization", true);

						setVisibleState(false);
					}}
					disabled={unpValueInput === "" ? true : false}
				>
					<ModalContentInput value={unpValueInput} onChange={changeInput} />
				</Modal>
			)}
			{!localUnp && userOrgList.length > 1 && (
				<Modal
					open={isVisible}
					close={() => dispatch(authentication.logout())}
					titleButton='продолжить'
					titleButton2='закрыть'
					buttonClick={() => {
						localStorage.setItem("unp", unpValue ?? userOrgList[0].unp);
						localStorage.setItem("Authorization", true);

						setVisibleState(false);
					}}
				>
					<ModalContentSelect
						value={unpValue ?? userOrgList[0].unp}
						onChange={changeSelect}
						arr={userOrgList}
						isOpen
					/>
				</Modal>
			)}

			{!isRequested && localUnp ? (
				<header className={cls.header_container}>
					<Container component='main' className={cls.container}>
						<div className={cls.header_logo_container}>
							<NavLink to={user.isSAAS === 0 ? "transactions" : "/"}>
								<img src={logo} alt='logo' />
							</NavLink>
						</div>
						<div className={cls.header_button_burger}>
							<div
								className={cls.menu_wrapper}
								onClick={() => setButtonBurger(!buttonBurger)}
							>
								<HamburgerMenu boolean={buttonBurger} />
							</div>
						</div>
						<div
							onMouseLeave={() => setStyleLeft({ ...styleLeft, width: 0 })}
							className={`${cls.header_tabs_container}`}
						>
							{menuContent}
							<span className={cls.header_line} style={styleLeft} />
						</div>
						<div className={cls.header_button_settings}>
							<NavLink
								exact
								className={cls.header_button_settings_link}
								activeClassName={cls.header_tab_active}
								to='settings'
							>
								<SettingsIcon />
							</NavLink>
						</div>
					</Container>
				</header>
			) : (
				<header className={cls.header_container}>
					<Container component='main' className={cls.container_skeleton}>
						<div className={cls.header_logo_container}>
							<Skeleton animation='wave' height={80} width={180} />
						</div>
						<div className={cls.header_button_burger}>
							<div className={cls.menu_wrapper}>
								<Skeleton animation='wave' height={33} width={20} />
							</div>
						</div>
						<div className={`${cls.header_tabs_container}`}>
							<Skeleton animation='wave' width={700} />
						</div>
					</Container>
				</header>
			)}
			<div className={cls.menu_conatiner_wrapper}>
				<div id='outer-container'>
					<Menu
						outerContainerId={"outer-container"}
						onStateChange={({ isOpen }) => {
							setButtonBurger(isOpen);
						}}
						right
						isOpen={buttonBurger}
						customBurgerIcon={false}
					>
						{menuContent}
					</Menu>
				</div>
			</div>
		</>
	);
};

export default Header;
