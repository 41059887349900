import React, { useState, useEffect, Fragment } from "react";
import {
	Card,
	TextField,
	Table,
	TableBody,
	TableContainer,
} from "@material-ui/core";
import cls from "./settings.module.scss";
import ButtonStyle from "../../components/ButtonStyle";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { toast } from "react-toastify";

import settings from "../../redux/actions/settings";

import SortHelper from "../../helpers/sortHelper";
import { useHandleRequestSort } from "../../helpers/handleRequestSort";
import EnhancedTableHead from "../../components/EnhancedTableHead";
import HandlerRowDesktopAndMobile from "../../components/HandlerRowDesktopAndMobile";
import MoreHorizMenu from "./components/MoreHorizMenu";
import HelmetComponent from "../../components/HelmetComponent";
import OrderList from "../../components/OrderList";
import Modal from "../../components/Modal";
import ModalContentAdd from "./components/Modals/ModalContentAdd";
import ModalContentDel from "./components/Modals/ModalContentDel";

const Settings = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {
		isMultiAcquirer,
		mainUserLogin,
		mainUserId,
		mainUserPersonName,
		orgShortName,
		unp,
		role,
	} = useSelector((state) => state.authentication.user);

	const { getEmail, listUser } = useSelector((state) => state.settings);

	const rows = listUser;
	const headCells = [
		{
			id: "login",
			align: "left",
			label: t("Логин"),
		},
		{
			id: "fio",
			align: "left",
			label: t("ФИО"),
		},
	];

	const [{ order, orderBy }, handleRequestSort] = useHandleRequestSort({
		order: "asc",
		orderBy: "login",
	});

	const [openModalUserAdd, setOpenModalUserAdd] = useState(false);
	const [openModalUserEdit, setOpenModalUserEdit] = useState(false);
	const [openModalUserDel, setOpenModalUserDel] = useState(false);

	const [btnDisabledModal, setBtnDisabledModal] = useState(true);

	const [btnFio, setBtnFio] = useState(false);
	const [btnEmail, setBtnEmail] = useState(false);
	const [mainFio, setMainFio] = useState("");
	const [email, setEmail] = useState("");
	const [errorEmail, setErrorEmail] = useState(false);

	const [newLogin, setNewLogin] = useState("");
	const [newFio, setNewFio] = useState("");

	const [currentUserId, setCurrentUserId] = useState("");
	const [currentUserLogin, setCurrentUserLogin] = useState("");
	const [currentUserFio, setCurrentUserFio] = useState("");

	const emptyFio = mainFio === "";
	const role1 = role === 1;
	// const role2 = role === 2;

	const emailRegex = /^\S+@\S+\.\S+$/;

	const changeFio = (e) => {
		setMainFio(e.target.value);
	};
	const changeEmail = (e) => {
		setEmail(e.target.value);
	};

	const submitUserAdd = () => {
		const wrap = {
			login: newLogin,
			fio: newFio,
		};

		dispatch(
			settings.addUser(wrap, () => {
				setOpenModalUserAdd(false);
				setNewLogin("");
				setNewFio("");
				dispatch(settings.listUser({}));
			})
		);
	};

	const submitUserEdit = () => {
		const wrap = {
			userId: currentUserId,
			fio: currentUserFio,
			login: currentUserLogin,
		};

		dispatch(
			settings.editUser(wrap, () => {
				setOpenModalUserEdit(false);
				dispatch(settings.listUser({}));
			})
		);
	};

	const submitUserDel = () => {
		dispatch(
			settings.delUser({ userId: currentUserId }, () => {
				setOpenModalUserDel(false);
				dispatch(settings.listUser({}));
			})
		);
	};

	useEffect(() => {
		dispatch(settings.listUser({}));
		dispatch(settings.getEmail({}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setMainFio(mainUserPersonName ?? "");
		setEmail(getEmail ?? "");
	}, [mainUserLogin, mainUserPersonName, getEmail]);

	useEffect(() => {
		if (currentUserLogin.match(emailRegex) && currentUserFio.length > 2)
			setBtnDisabledModal(false);
		else setBtnDisabledModal(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUserLogin, currentUserFio]);

	useEffect(() => {
		if (newFio.length > 2 && newLogin.match(emailRegex))
			setBtnDisabledModal(false);
		else setBtnDisabledModal(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newLogin, newFio]);

	useEffect(() => {
		if (isMultiAcquirer) {
			if (email === "") setErrorEmail(false);
			else
				email?.match(emailRegex) ? setErrorEmail(false) : setErrorEmail(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email]);

	return (
		<>
			<HelmetComponent
				titlePage={t("Настройки аккаунта")}
				description={t("Настройки аккаунта")}
				title={t("Настройки аккаунта")}
			/>

			<Card className={cls.container_wrapper}>
				<h1 className={cls.container_heading}>
					<Trans>Настройки аккаунта</Trans>
				</h1>
				{orgShortName && (
					<div className={cls.text_block}>
						<Trans>Наименование:</Trans> {orgShortName}
					</div>
				)}
				{unp && (
					<div className={cls.text_block}>
						<Trans>УНП:</Trans> {unp}
					</div>
				)}

				<div className={cls.text_block}>
					<h2>
						<Trans>Основной пользователь</Trans>
					</h2>
				</div>
				<div className={cls.button_wrapper}>
					<div className={cls.button_block}>
						<TextField
							disabled
							variant='outlined'
							size='small'
							label='Логин'
							value={mainUserLogin ?? ""}
						/>
					</div>
					<div className={cls.button_block}>
						<span>
							<TextField
								error={emptyFio ? true : false}
								helperText={emptyFio && "Введите ФИО"}
								disabled={btnFio ? false : true}
								variant='outlined'
								size='small'
								label='ФИО'
								value={mainFio}
								onChange={changeFio}
							/>
						</span>
						{role1 && (
							<ButtonStyle
								disabled={emptyFio ? true : false}
								onClick={() => {
									setBtnFio((prevState) => !prevState);
									btnFio &&
										dispatch(
											settings.editUser({
												userId: mainUserId,
												fio: mainFio,
											})
										);
								}}
							>
								{btnFio ? "сохранить" : "изменить"}
							</ButtonStyle>
						)}
					</div>
				</div>

				<div className={cls.text_block}>
					<h2>
						<Trans>Дополнительные пользователи</Trans>
					</h2>
				</div>
				<TableContainer>
					<Table
						aria-labelledby='tableTitle'
						size='medium'
						aria-label='enhanced table'
					>
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={headCells}
						/>
						<TableBody>
							{SortHelper(rows, order, orderBy).map((row, index) => {
								return (
									<Fragment key={index + Math.random()}>
										{role1 && (
											<MoreHorizMenu
												role={role}
												clickChange={() => {
													setCurrentUserId(row.userId);
													setCurrentUserLogin(row.login);
													setCurrentUserFio(row.fio);
													setOpenModalUserEdit(true);
												}}
												clickDelete={() => {
													setCurrentUserId(row.userId);
													setCurrentUserLogin(row.login);
													setCurrentUserFio(row.fio);
													setOpenModalUserDel(true);
												}}
											/>
										)}

										<HandlerRowDesktopAndMobile
											headCells={headCells}
											row={row}
											column
											minimize
										/>
									</Fragment>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				{role1 && (
					<div className={`${cls.button_block} ${cls.button_block_users}`}>
						<ButtonStyle onClick={() => setOpenModalUserAdd(true)}>
							<Trans>добавить</Trans>
						</ButtonStyle>
					</div>
				)}

				{isMultiAcquirer ? (
					<>
						<div className={`${cls.text_block} ${cls.text_block_email}`}>
							<h2>
								<Trans>Для автоматической отправки "Контрольной ленты"</Trans>
							</h2>
							<div className={cls.button_block}>
								Введите email получателя
								<TextField
									fullWidth
									type='email'
									className={cls.text_email}
									error={errorEmail && btnEmail ? true : false}
									helperText={errorEmail && btnEmail && "Введите E-mail"}
									disabled={btnEmail ? false : true}
									variant='outlined'
									size='small'
									label='E-mail'
									value={email}
									onChange={changeEmail}
								/>
								{role1 && (
									<>
										<ButtonStyle
											disabled={errorEmail ? true : false}
											onClick={() => {
												setBtnEmail((prevState) => !prevState);
												!errorEmail &&
													btnEmail &&
													dispatch(
														settings.editEmail({ email: email }, () =>
															email !== ""
																? toast.success("E-mail успешно изменён")
																: toast.warn(
																		'E-mail успешно изменён. Автоматическая отправка "Контрольной ленты" приостановлена.'
																  )
														)
													);
											}}
										>
											{btnEmail ? "Сохранить" : "Изменить"}
										</ButtonStyle>
										{btnEmail && (
											<ButtonStyle
												onClick={() => {
													setBtnEmail((prevState) => !prevState);
													setEmail("");
													// setEmail(getEmail);
												}}
											>
												Отменить
											</ButtonStyle>
										)}
									</>
								)}
							</div>
						</div>

						<OrderList role={role} />
					</>
				) : null}
			</Card>

			{/* MODALS */}
			<Modal
				open={openModalUserAdd}
				close={() => {
					setOpenModalUserAdd(false);
					setNewLogin("");
					setNewFio("");
					setBtnDisabledModal(true);
				}}
				titleButton={t("Добавить")}
				titleButton2={t("Отменить")}
				disabled={btnDisabledModal}
				buttonClick={submitUserAdd}
			>
				<ModalContentAdd
					header={t("Добавление пользователя")}
					login={newLogin}
					fio={newFio}
					onChangeLogin={(e) => setNewLogin(e.target.value)}
					onChangeFio={(e) => setNewFio(e.target.value)}
				/>
			</Modal>
			<Modal
				open={openModalUserEdit}
				close={() => {
					setOpenModalUserEdit(false);
					setBtnDisabledModal(true);
				}}
				titleButton={t("Изменить")}
				titleButton2={t("Отменить")}
				disabled={btnDisabledModal}
				buttonClick={submitUserEdit}
			>
				<ModalContentAdd
					header={t("Изменение ФИО пользователя")}
					login={currentUserLogin}
					fio={currentUserFio}
					onChangeLogin={(e) => setCurrentUserLogin(e.target.value)}
					onChangeFio={(e) => setCurrentUserFio(e.target.value)}
					disabledLogin
				/>
			</Modal>
			<Modal
				open={openModalUserDel}
				close={() => setOpenModalUserDel(false)}
				titleButton={t("Удалить")}
				titleButton2={t("Отменить")}
				buttonClick={submitUserDel}
			>
				<ModalContentDel login={currentUserLogin} fio={currentUserFio} />
			</Modal>
		</>
	);
};

export default Settings;
