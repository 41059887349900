import React, {
	useEffect,
	useState,
	Fragment,
	useRef,
	useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Box,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { utils, writeFileXLSX } from "xlsx";

import cls from "../../transactions.module.scss";

import transactions from "../../../../redux/actions/transactions";

import HelmetComponent from "../../../../components/HelmetComponent";
import TabsComponenet from "../../../../components/Tabs";
import TranList from "../TranList";
import BatchList from "../BatchList";
import Analytic from "../Analytic";

import handleClickRow from "../../../../helpers/handleClickRow";
import SortHelper from "../../../../helpers/sortHelper";
import getAllPropValues from "../../../../helpers/getAllPropValues";
import { useHandleRequestSort } from "../../../../helpers/handleRequestSort";

import EnhancedTableHead from "../EnhancedTableHead";
import EnhancedTableToolbar from "../EnhancedTableToolbar";
import ButtonStyle from "../../../../components/ButtonStyle";
import HandlerRowDesktopAndMobile from "../../../../components/HandlerRowDesktopAndMobile";
import Loading from "../../../../components/Loading";
import ModalFilter from "../../../../components/ModalFilter";
import EnhancedTablePagination from "../../../../components/EnhancedTablePagination";
import DataPickerComponent from "../../../../components/DataPickerComponent";
import moment from "moment";

const DevicesList = ({
	loading,
	rows,
	rowsLength,
	tabsValue,
	tabsChange,
	dispatchTranList,
	dispatchBatchList,
	dispatchAnalyticEmi,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { isRequested, tranList, batchList, analyticEmi } = useSelector(
		(state) => state.transactions
	);

	const headCells = [
		{
			id: "sn",
			numeric: false,
			disablePadding: true,
			align: "left",
			label: t("Серийный номер"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "mod",
			numeric: false,
			disablePadding: false,
			align: "left",
			label: t("Модель"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "ots",
			numeric: false,
			disablePadding: false,
			align: "left",
			label: t("ОТС"),
			bool: false,
			mobileLine: false,
		},
		{
			id: "adr",
			numeric: false,
			disablePadding: false,
			align: "left",
			label: t("Адрес"),
			bool: false,
			mobileLine: false,
			sort: true,
		},
		{
			id: "bnk",
			numeric: false,
			disablePadding: false,
			align: "left",
			label: t("TID-Банк-эквайер"),
			bool: false,
			mobileLine: false,
		},
	];

	const prevStartDateRef = useRef();
	const prevEndDateRef = useRef();
	const prevSelectedRef = useRef();

	const [params, setParams] = useState({});

	const [{ order, orderBy }, handleRequestSort] = useHandleRequestSort({
		order: "asc",
		orderBy: "adr",
	});

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(startDate);

	const lastWeek = new Date(new Date().setDate(new Date().getDate() - 7));
	const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));

	const [maxDate, setMaxDate] = useState(
		new Date().setMonth(new Date().getMonth() + 1)
	);

	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		rowsPerPage -
		Math.min(rowsPerPage, (rows ? rows.length : 0) - 0 * rowsPerPage);

	const [filterOpen, setFilterOpen] = useState(false);
	const [filterActive, setFilterActive] = useState({});

	const handleChangeFilterRows = (array, params) => {
		return array.filter((el) => {
			if (Object.keys(params).length) {
				let status = [];
				Object.keys(params).forEach((key) => {
					if (el[key] === params[key]) {
						status.push(true);
					} else {
						let statusEl = false;
						if (Array.isArray(params[key])) {
							params[key].forEach((parEl) => {
								if (Boolean(el[key].find((elEl) => elEl === parEl))) {
									statusEl = true;
								}
							});
						}
						statusEl ? status.push(true) : status.push(false);
					}
				});
				let finishSearch = false;
				if (status.find((bool) => bool === false) === undefined) {
					finishSearch = true;
				}
				return finishSearch;
			}
			return true;
		});
	};

	const [newRows, setNewRows] = useState(
		handleChangeFilterRows(rows, filterActive)
	);

	useEffect(() => {
		setNewRows(handleChangeFilterRows(rows, filterActive));
	}, [filterActive, rows]);

	// Data export to .xlsx file https://docs.sheetjs.com/docs/demos/frontend/react/
	const exportFile = useCallback(() => {
		const exportStartDate = startDate.toLocaleString([], {
			dateStyle: "short",
		});
		const exportEndDate = endDate.toLocaleString([], { dateStyle: "short" });
		const deviceLabels = [
			"Серийный номер",
			"Модель",
			"ОТС",
			"Адрес",
			"TID-Банк-эквайер",
		];
		const tranLabels = [
			"Терминал",
			"Банк-эквайер",
			"Дата-Время",
			"№ чека",
			"Карта",
			"Сумма",
			"Операция",
			"Код результата",
			"Описание результата",
			"RRN",
			"Код авторизации",
			"Банк-эмитент",
		];
		const exportDevices = selected;
		exportDevices.forEach(function (item) {
			item.bnk = item.bnk.replace(/<br>/gi, "\n");
		});
		const exportTrans = tranList.result;
		exportTrans.forEach(function (item) {
			delete item.state;
			delete item.id;
			delete item.cati;
		});
		const tranListExcelCols = [
			{ wch: 15 }, //1 Терминал
			{ wch: 25 }, //2 Банк-эквайер
			{ wch: 20 }, //3 Дата-Время
			{ wch: 7 }, //4 № чека
			{ wch: 10 }, //5 Карта
			{ wch: 10 }, //6 Сумма
			{ wch: 10 }, //7 Операция
			{ wch: 5 }, //8 Код результата
			{ wch: 20 }, //9 Описание результата
			{ wch: 15 }, //10 RRN
			{ wch: 10 }, //11 Код авторизации
			{ wch: 25 }, //12 Банк-эмитент
		];
		const devicesListExcelCols = [
			{ wch: 15 }, //1 Серийный номер
			{ wch: 15 }, //2 Модель
			{ wch: 15 }, //3 ОТС
			{ wch: 30 }, //4 Адрес
			{ wch: 33 }, //5 TID-Банк-эквайер
		];
		const devicesListExcel = utils.json_to_sheet(exportDevices, {
			header: ["sn", "mod", "ots", "adr", "bnk"],
		});
		devicesListExcel["!cols"] = devicesListExcelCols;
		const tranListExcel = utils.json_to_sheet(exportTrans, {
			header: [
				"sn",
				"bnk",
				"trnDt",
				"chqN",
				"pan",
				"trnAmt",
				"trnType",
				"resC",
				"resD",
				"rrn",
				"auth",
				"emi",
			],
		});
		tranListExcel["!cols"] = tranListExcelCols;
		const wb = utils.book_new();

		const typeNumberCol = (arr, numberC) => {
			let range = utils.decode_range(arr["!ref"]);
			range.s.c = range.e.c = numberC;
			for (let R = range.s.r; R <= range.e.r; ++R) {
				let addr = utils.encode_cell({ r: R, c: numberC });
				if (!arr[addr]) continue;
				arr[addr].z = "#,##0";
			}

			if (!arr["!cols"]) arr["!cols"] = [];
			if (!arr["!cols"][numberC]) arr["!cols"][numberC] = { wch: 10 };
			return (arr["!cols"][numberC].z = "#,##0");
		};
		typeNumberCol(tranListExcel, 5);

		utils.sheet_add_aoa(devicesListExcel, [deviceLabels], { origin: "A1" });
		utils.sheet_add_aoa(tranListExcel, [tranLabels], { origin: "A1" });
		utils.book_append_sheet(wb, devicesListExcel, "Список устройств");
		utils.book_append_sheet(wb, tranListExcel, "Транзакции");

		writeFileXLSX(
			wb,
			`Транзакции с ${exportStartDate} по ${exportEndDate}.xlsx`,
			{ cellStyles: true }
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tranList.result]);

	useEffect(() => {
		setParams({
			page: tranList?.page,
			sn: getAllPropValues(selected, "sn"),
			dtStart: moment(startDate).format("DD.MM.YYYY"),
			dtEnd: moment(endDate).format("DD.MM.YYYY"),
		});

		prevStartDateRef.current = startDate;
		prevEndDateRef.current = endDate;
		prevSelectedRef.current = selected;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected, startDate, endDate]);

	useEffect(() => {
		if (selected.length > 0 && startDate && endDate) {
			if (tabsValue === 0) dispatchTranList(params);
			if (tabsValue === 1) dispatchBatchList(params);
			if (tabsValue === 2) dispatchAnalyticEmi(params);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabsValue]);

	useEffect(() => {
		setParams({ ...params, page: 1 });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		prevSelectedRef.current,
		prevStartDateRef.current,
		prevEndDateRef.current,
	]);

	const [activeBtn, setActiveBtn] = useState("");

	const labels = [t("Транзакции"), t("Бизнес-дни"), t("Статистика")];

	return (
		<>
			<Paper className={cls.paper}>
				<EnhancedTableToolbar
					filterActive={filterActive}
					setFilterActive={setFilterActive}
					setFilterOpen={() => setFilterOpen(!filterOpen)}
					numSelected={selected.length}
					title={t("Список устройств")}
				/>
				<TableContainer className={cls.table_container_wrapper}>
					<Loading load={loading} />
					<Table
						className={`${cls.table_container} ${cls.minimize_padding}`}
						aria-labelledby='tableTitle'
						size='medium'
						aria-label='enhanced table'
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={() =>
								handleClickRow.handleSelectAllRows(
									selected,
									newRows,
									setSelected
								)
							}
							onRequestSort={handleRequestSort}
							rowCount={rowsLength}
							headCells={headCells}
							sort
							checkbox
						/>
						<ModalFilter
							setFilterOpen={() => setFilterOpen(false)}
							filterOpen={filterOpen}
							setFilterActive={setFilterActive}
							headCells={headCells}
							rows={rows}
							filterActive={filterActive}
							filterRows={newRows}
						/>
						<TableBody>
							{SortHelper(newRows, order, orderBy)
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(row);
									return (
										<Fragment key={index}>
											<HandlerRowDesktopAndMobile
												headCells={headCells}
												handleClick={(event, row) => {
													handleClickRow(event, row, selected, setSelected);
												}}
												isItemSelected={isItemSelected}
												row={row}
												cursor
												checkbox
												column
												index={index}
												paddingCheck
												minimize
											/>
										</Fragment>
									);
								})}
							{!newRows.length && rows.length ? (
								<TableRow style={{ height: 53 * 1 }}>
									<TableCell colSpan={headCells.length + 1}>
										<div className={cls.filter_empty_text}>
											{t(
												"По вашему фильтру ничего не найдено, выберите другие настройки"
											)}
											<div
												className={cls.reset_filter}
												onClick={() => setFilterActive({})}
											>
												{t("сбросить фильтр")}
											</div>
										</div>
									</TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
				{!emptyRows && (
					<EnhancedTablePagination
						count={newRows.length}
						page={page}
						rowsPerPage={rowsPerPage}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
				<Toolbar className={cls.toolbar}>
					<Typography className={cls.title} variant='h5' id='tableTitle'>
						<span>{t("Выберите период")}</span>
					</Typography>
				</Toolbar>
				<Box className={cls.date_block}>
					<div className={cls.date_block_week}>
						<ButtonStyle
							onClick={() => {
								setStartDate(lastWeek);
								setEndDate(new Date());
								setActiveBtn("week");
							}}
							styleVariable={activeBtn === "week" ? "default" : "outlined"}
						>
							{t("Последняя неделя")}
						</ButtonStyle>
					</div>
					<div className={cls.date_block_month}>
						<ButtonStyle
							onClick={(e) => {
								setStartDate(lastMonth);
								setEndDate(new Date());
								setActiveBtn("month");
							}}
							styleVariable={activeBtn === "month" ? "default" : "outlined"}
						>
							{t("Последний месяц")}
						</ButtonStyle>
					</div>
					<div className={cls.date_block_period}>
						<div className={cls.date_block_period_from}>
							{t("Период с")}:
							<DataPickerComponent
								active={activeBtn === "period" && true}
								minDate
								maxDate={endDate}
								selected={startDate}
								onChange={(date) => {
									setStartDate(date);
									setEndDate(
										new Date(
											date.getFullYear(),
											date.getMonth() + 1,
											date.getDate()
										)
									);
									setMaxDate(
										new Date(
											date.getFullYear(),
											date.getMonth() + 1,
											date.getDate()
										)
									);
									setActiveBtn("period");
								}}
								selectsStart
								startDate={startDate}
								endDate={endDate}
								peekNextMonth
								showMonthDropdown
								popperPlacement='top-start'
							/>
						</div>
						<div className={cls.date_block_period_to}>
							{t("по")}:
							<DataPickerComponent
								active={activeBtn === "period" && true}
								selected={endDate}
								onChange={(date) => {
									setEndDate(date);
									setActiveBtn("period");
								}}
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								minDate={startDate}
								maxDate={maxDate}
								peekNextMonth
								showMonthDropdown
								popperPlacement='top-end'
							/>
						</div>
					</div>
					<Box className={cls.date_block_confirm}>
						<ButtonStyle
							onClick={() => {
								if (selected.length > 0 && startDate && endDate) {
									if (tabsValue === 0) dispatchTranList(params);
									if (tabsValue === 1) dispatchBatchList(params);
									if (tabsValue === 2) dispatchAnalyticEmi(params);
								}
							}}
						>
							{t("Применить")}
						</ButtonStyle>
						{tabsValue === 0 && (
							<ButtonStyle
								disabled={tranList?.result?.length > 0 ? false : true}
								onClick={exportFile}
							>
								{t("Экспорт в Excel")}
							</ButtonStyle>
						)}
					</Box>
				</Box>
			</Paper>

			<Paper className={cls.paper}>
				<Loading load={isRequested} />
				<TabsComponenet
					tabClass={cls.tab}
					onChange={(e, value) => tabsChange(value)}
					value={tabsValue}
					labels={labels}
				/>

				{tabsValue === 0 && (
					<>
						<HelmetComponent
							titlePage={labels[0]}
							description={labels[0]}
							title={labels[0]}
						/>
						{tranList?.result ? (
							<TranList
								params={params}
								loading={isRequested}
								rows={tranList.result}
								rowsCount={tranList.rowCount}
								rowsTotal={tranList.rowTotal}
								title={labels[0]}
							/>
						) : (
							<Toolbar className={cls.toolbar}>
								<Typography
									className={cls.title}
									variant='h5'
									id='tranTitle'
									align='center'
								>
									<span>{t("Нет данных по транзакциям")}</span>
								</Typography>
							</Toolbar>
						)}
					</>
				)}

				{tabsValue === 1 && (
					<>
						<HelmetComponent
							titlePage={labels[1]}
							description={labels[1]}
							title={labels[1]}
						/>
						{batchList?.length ? (
							<BatchList
								loading={isRequested}
								rows={batchList}
								title={labels[1]}
								dispatchEmailBatch={(params, callback) =>
									dispatch(
										transactions.emailBatch(
											params,
											(data) => callback && callback(data)
										)
									)
								}
							/>
						) : (
							<Toolbar className={cls.toolbar}>
								<Typography
									className={cls.title}
									variant='h5'
									id='tranTitle'
									align='center'
								>
									<span>{t("Нет данных по бизнес-дням")}</span>
								</Typography>
							</Toolbar>
						)}
					</>
				)}

				{tabsValue === 2 && (
					<>
						<HelmetComponent
							titlePage={labels[2]}
							description={labels[2]}
							title={labels[2]}
						/>
						{analyticEmi ? (
							<Analytic
								loading={isRequested}
								params={analyticEmi}
								title={labels[2]}
								dispatchOrderList={(callback) => {
									dispatch(
										transactions.orderList(
											{},
											(data) => callback && callback(data)
										)
									);
								}}
							/>
						) : (
							<Toolbar className={cls.toolbar}>
								<Typography
									className={cls.title}
									variant='h5'
									id='tranTitle'
									align='center'
								>
									<span>{t("Нет данных для статистики")}</span>
								</Typography>
							</Toolbar>
						)}
					</>
				)}
			</Paper>
		</>
	);
};

export default DevicesList;
