import React from "react";
import cls from "./modal.module.scss";
import ButtonStyle from "../ButtonStyle";
import { Dialog } from "@material-ui/core";

const Modal = ({
	open,
	close,
	disabled,
	children,
	titleButton,
	titleButton2,
	buttonClick,
	styleButton,
	...props
}) => {
	return (
		<Dialog
			open={open}
			onClose={close}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			className={cls.modal_wrapper}
			{...props}
		>
			<div className={cls.modal_content_wrapper}>
				<div
					className={cls.close}
					onClick={close}
				/>
				{children}
				<div
					className={`${cls.modal_button_block} ${
						titleButton ? cls.modal_button_block_two : ""
					}`}
				>
					{titleButton && (
						<ButtonStyle
							styleVariable={styleButton ? "default" : "outlined"}
							onClick={buttonClick}
							disabled={disabled}
						>
							{titleButton}
						</ButtonStyle>
					)}
					{titleButton2 && (
						<ButtonStyle
							styleVariable='outlined'
							onClick={close}
						>
							{titleButton2}
						</ButtonStyle>
					)}
				</div>
			</div>
		</Dialog>
	);
};
export default Modal;
