
const initialvalues = {
  loading: false,
  styleChange: false
};

const styleChange = (state = initialvalues, action) => {
  switch (action.type) {
    case "STYLE_CHANGE_ACTIVE":
      return {
        ...state,
        loading: true,
        styleChange: !state.styleChange,
      };
    default:
      return state;
  }
}

export default styleChange;
