import types from './../Types'

const initialvalues = {
  isRequested: false,
  deviceUpdate: {
    error: 0
  },
  deviceDisable: {
    error: 0
  },
  deviceExclude: {
    error: 0
  },
  deviceBind: {
    error: 0
  },
  device: []
};

const devices = (state = initialvalues, action) => {
  switch (action.type) {
    case types.DEVICE_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.DEVICE_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        deviceUpdate: action.deviceUpdate
      };
    case types.DEVICE_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.CURRENT_DEVICE_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.CURRENT_DEVICE_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        device: action.device
      };
    case types.CURRENT_DEVICE_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.CURRENT_DEVICE_DISABLE_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.CURRENT_DEVICE_DISABLE_SUCCESS:
      return {
        ...state,
        isRequested: false,
        deviceExclude: action.deviceExclude
      };
    case types.CURRENT_DEVICE_DISABLE_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.CURRENT_DEVICE_EXCLUDE_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.CURRENT_DEVICE_EXCLUDE_SUCCESS:
      return {
        ...state,
        isRequested: false,
        deviceDisable: action.deviceDisable
      };
    case types.CURRENT_DEVICE_EXCLUDE_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.CURRENT_DEVICE_BIND_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.CURRENT_DEVICE_BIND_SUCCESS:
      return {
        ...state,
        isRequested: false,
        deviceBind: action.deviceBind
      };
    case types.CURRENT_DEVICE_BIND_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default devices;