import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { Select, MenuItem } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import statusIconGenerator from "../../helpers/statusIconGenerator";
import { useTranslation } from "react-i18next";
import qs from "qs";

const ModalFilter = ({
	setFilterOpen,
	filterOpen,
	setFilterActive,
	headCells,
	rows,
	filterActive,
	filterRows,
}) => {
	const { t } = useTranslation();
	const { search } = useLocation();
	const { push, pathname } = useHistory();

	const [arrayFilter, setArrayFilter] = useState([]);

	useEffect(() => {
		const newArraySelect = {};
		if (!rows.length) {
			return;
		}

		Object.keys(rows[0]).forEach((key) => {
			if (headCells.find(({ id }) => id === key)) {
				newArraySelect[key] = [];
			}
		});

		rows.forEach((el, index) => {
			// Object.keys(el).forEach((k) => el[k] === null && delete el[k]); // Очищаем null

			Object.keys(el).forEach((key, index) => {
				if (
					newArraySelect[key]?.indexOf(el[key]) === -1 &&
					headCells.find(({ id }) => id === key)
				) {
					newArraySelect[key].push(el[key]);
				}
			});
		});

		setArrayFilter(newArraySelect);
	}, [headCells, rows]);

	useEffect(() => {
		if (search) {
			const a = qs.parse(search, { ignoreQueryPrefix: true });
			setFilterActive(a);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hendlerValueSelect = (headCell, index) => {
		if (filterActive[headCell.id] === null) {
			return "-";
		}
		if (
			filterActive[headCell.id] ||
			(filterActive[headCell.id] === 0 && headCell.id === "flagAuto")
		) {
			return filterActive[headCell.id];
		}
		if (arrayFilter[index]?.length === 1) {
			return arrayFilter[index][0];
		}
		return "Все";
	};

	return (
		<Modal
			open={filterOpen}
			close={setFilterOpen}
			titleButton={t("сбросить")}
			titleButton2={t("применить")}
			buttonClick={() => setFilterActive({})}
		>
			<h1>{t("Фильтр")}</h1>
			{headCells.map((headCell, index) => (
				<div
					key={index}
					style={{ marginBottom: "20px" }}
				>
					<span
						dangerouslySetInnerHTML={{
							__html: headCell.label,
						}}
					/>
					:{" "}
					<Select
						value={hendlerValueSelect(headCell, index)}
						onChange={({ target: { value } }) => {
							if (search) {
								push(pathname);
							}
							if (value === "Все") {
								const newFilterActive = { ...filterActive };
								delete newFilterActive[headCell.id];
								return setFilterActive(newFilterActive);
							}
							setFilterActive({
								...filterActive,
								[headCell.id]: value === "-" ? null : value,
							});
						}}
						style={{ width: "100%" }}
					>
						<MenuItem value={"Все"}>{t("Все")}</MenuItem>
						{arrayFilter?.[headCell.id]?.map((el) => {
							// eslint-disable-next-line array-callback-return
							if (el === null) return;

							return (
								<MenuItem
									key={el}
									name={headCell.id}
									value={el}
								>
									<span
										style={{ fontFamily: "inherit" }}
										dangerouslySetInnerHTML={{
											__html: headCell.doubleIcon
												? statusIconGenerator(el, "doubleIcon", t)
												: headCell.icon
												? statusIconGenerator(el, null, t)
												: headCell.id === "flagAuto"
												? el > 0
													? t("да")
													: t("нет")
												: el,
										}}
									/>
								</MenuItem>
							);
						})}
					</Select>
				</div>
			))}
			{t("найдено")}: {filterRows.length} {t("записей")}
		</Modal>
	);
};

export default ModalFilter;
