const SortHelper = (array, order, orderBy) => {

	const descendingComparator = (a, b, orderBy) => {
		if (orderBy === 'operDt' || orderBy === 'trnDt' || orderBy === 'openDt' || orderBy === 'closeDt') {
			const toB = new Date(b[orderBy].replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'));
			const toA = new Date(a[orderBy].replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'));
			if (toB < toA) {
				return -1;
			}
			if (toB > toA) {
				return 1;
			}
			return 0;
		} else {
			if (b[orderBy] < a[orderBy]) {
				return -1;
			}
			if (b[orderBy] > a[orderBy]) {
				return 1;
			}
			return 0;
		}
	}

	const getComparator = (order_par, orderBy_par) => {
		return order_par === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy_par)
			: (a, b) => -descendingComparator(a, b, orderBy_par);
	}

	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const newOrder = getComparator(order, orderBy)(a[0], b[0]);
		if (newOrder !== 0) return newOrder;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

export default SortHelper;