import React from "react";
import { Link, useLocation } from "react-router-dom";
import cls from "./login.module.scss";
import {
	CssBaseline,
	Container,
	CircularProgress,
	Card,
} from "@material-ui/core";
import { useFormik } from "formik";
import InputForm from "../../components/form/InputForm";
import validate from "./component/validate";
import { useDispatch, useSelector } from "react-redux";
import authentication from "../../redux/actions/authentication";
import ButtonStyle from "../../components/ButtonStyle";
// import CheckboxStyle from '../../components/CheckboxStyle';
import logo from "../../img/zPOS_logo.svg";
import qs from "qs";

const Login = () => {
	const { search } = useLocation();
	const { USER_LOGIN } = qs.parse(search, { ignoreQueryPrefix: true });
	const dispatch = useDispatch();

	const { resetPassword, isRequested: loading } = useSelector(
		(state) => state.authentication
	);

	const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
		useFormik({
			initialValues: {
				email: USER_LOGIN ? USER_LOGIN : "",
				password: "",
				// rememberMe: false,
				dopBlock: resetPassword,
			},
			validate: (values) => validate(values, resetPassword),
			onSubmit: (values, formikBag) => {
				if (resetPassword) {
					const { firstPassword } = values;
					dispatch(
						authentication.resetPwd(firstPassword, () => formikBag.resetForm())
					);
				} else {
					dispatch(authentication.login(values));
				}
			},
		});

	return (
		<Container
			component='main'
			maxWidth='sm'
			className={cls.login_container_wrapper}
		>
			<Card className={cls.login_block_card}>
				<CssBaseline />
				<div className={cls.login_block_content}>
					<a href='https://zpos.by/' target='_blank' rel='noopener noreferrer'>
						<img src={logo} alt='logo' />
					</a>
					<h1 className={cls.login_block_h1}>Вход в личный кабинет</h1>
					<form onSubmit={handleSubmit}>
						<InputForm
							handleChange={handleChange}
							handleBlur={handleBlur}
							fullWidth
							values={values ?? ""}
							name='email'
							autoComplete='email'
							label='Логин'
							touched={touched}
							errors={errors}
							disabled={loading || resetPassword}
						/>
						<InputForm
							handleChange={handleChange}
							handleBlur={handleBlur}
							fullWidth
							values={values ?? ""}
							name='password'
							label='Пароль'
							touched={touched}
							errors={errors}
							type='password'
							disabled={loading || resetPassword}
						/>
						<div className={cls.login_button_wraper}>
							{resetPassword && (
								<>
									<h1 className={cls.login_block_h1}>Введите новый пароль</h1>
									<InputForm
										handleChange={handleChange}
										handleBlur={handleBlur}
										fullWidth
										values={values ?? ""}
										name='firstPassword'
										label='Новый пароль'
										touched={touched}
										errors={errors}
										type='password'
										disabled={loading}
									/>
									<InputForm
										handleChange={handleChange}
										handleBlur={handleBlur}
										fullWidth
										values={values ?? ""}
										name='secondPassword'
										label='Подтвердить новый пароль'
										touched={touched}
										errors={errors}
										type='password'
										disabled={loading}
									/>
								</>
							)}
							<ButtonStyle type='submit' disabled={loading}>
								{resetPassword ? "Сохранить" : "Войти"}
							</ButtonStyle>
							{loading && (
								<CircularProgress size={24} className={cls.login_loading} />
							)}
						</div>
					</form>

					<div className={cls.login_remember_me_container}>
						{/* <CheckboxStyle
                        disabled={loading}
                        checked={values?.rememberMe}
                        onChange={handleChange}
                        name='rememberMe'
                    /> */}
						{!resetPassword && (
							<Link to='/restore'>забыли пароль или не можете войти?</Link>
						)}
						{/* Запомните меня */}
					</div>
				</div>
			</Card>
		</Container>
	);
};

export default Login;
