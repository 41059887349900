const validate = (
	values,
	resetPassword /* only available when using withFormik */
) => {
	const errors = {};
	if (!values.email) {
		errors.email = "Введите логин";
	}
	//   else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
	//     errors.email = 'Проверьте правильность ввода почты';
	//   }

	if (!values.password) {
		errors.password = "Введите пароль";
	}
	// else if (values.password.length < 8) {
	// 	errors.password = "Пароль слишком короткий";
	// }
	if (resetPassword) {
		// if (!/^[A-Z0-9]{8,}$/i.test(values.firstPassword)) {
		// 	errors.firstPassword =
		// 		"Пароль должен содержать цифры и буквы. Длина пароля 8 и более символов.";
		// }
		if (!values.firstPassword) {
			errors.firstPassword = "Введите пароль";
		}
		if (!values.secondPassword) {
			errors.secondPassword = "Введите пароль";
		}
		if (
			values.secondPassword !== values.firstPassword &&
			values.secondPassword &&
			values.firstPassword
		) {
			errors.secondPassword = "Не совпадает";
			errors.firstPassword = "Не совпадает";
		}
	}

	return errors;
};

export default validate;
