import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import cls from "./modalContent.module.scss";
import { useTranslation } from "react-i18next";

const ModalContent = ({ close }) => {
	const { t } = useTranslation();
	const modalContent = useSelector((state) => state.services.orderPay);

	return (
		<div className={cls.modal_content_wrapper}>
			<div className={cls.modal_content_head}>
				<h2>{t("Спасибо!")}</h2>
				<h2>{t("Ваш заказ оформлен!")}</h2>
			</div>
			<div className={cls.modal_content_inform}>
				{t("информация о заказе:")}
			</div>
			<div className={cls.modal_content_main}>
				<span>
					{t("Заказ №")}
					{modalContent?.orderNum}
				</span>
			</div>
			<div className={cls.modal_content_link}>
				<Link
					onClick={close}
					to='/orders'
				>
					{t("перейти к списку заказов")}
				</Link>
			</div>
		</div>
	);
};

ModalContent.defaultProps = {
	close: () => {},
};

export default ModalContent;
