import React from "react";
import PropTypes from "prop-types";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Toolbar, Typography, IconButton, Tooltip } from "@material-ui/core";
import cls from "./style.module.scss";
import statusIconGenerator from "../../../../../../helpers/statusIconGenerator";
import { useTranslation } from "react-i18next";

const EnhancedTableToolbar = ({
	numSelected,
	setFilterOpen,
	filterActive,
	setFilterActive,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<Toolbar className={`${cls.equipment_and_services_table_toolbar}`}>
				<Typography
					className={cls.equipment_and_services_table_title}
					variant='h5'
					component='h1'
					id='tableTitle'
				>
					<span>{t("Моё оборудование")}</span>
					{numSelected > 0 && (
						<span className={cls.equipment_and_services_table_toolbar_selected}>
							{numSelected} {t("Выбрано")}
						</span>
					)}
				</Typography>

				<Typography
					className={cls.equipment_and_services_table_title}
					variant='h6'
					id='tableTitle'
				>
					<span>
						{t("Пожалуйста, подтвердите ваш заказ, поступивший с сайта zposby")}
					</span>
				</Typography>

				<Tooltip
					title={t("Список фильтров")}
					onClick={setFilterOpen}
					className={cls.equipment_and_services_filter_button}
				>
					<IconButton aria-label={t("Список фильтров")}>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			</Toolbar>
			<div className={cls.block_filter_all_wrapper}>
				{Object.keys(filterActive).map((el, index) => {
					let a = filterActive[el];
					if (Array.isArray(a)) {
						return a.map((element) => {
							element = statusIconGenerator(element, null, t);
							return (
								<div
									key={element}
									className={cls.current_block_filter_wrapper}
									title={filterActive[element]}
									onClick={() => {
										const newFilterActive = { ...filterActive };
										delete newFilterActive[el];
										setFilterActive(newFilterActive);
									}}
								>
									<div
										className={`${cls.current_block_filter} ${
											el === "servStatusAll" ? cls.current_block_filter_top : ""
										}`}
									>
										{element}
									</div>
								</div>
							);
						});
					}
					if (el === "servStatus") {
						a = statusIconGenerator(a, null, t);
					}
					return (
						<div
							key={index}
							className={cls.current_block_filter_wrapper}
							title={filterActive[el]}
							onClick={() => {
								const newFilterActive = { ...filterActive };
								delete newFilterActive[el];
								setFilterActive(newFilterActive);
							}}
						>
							<div
								className={`${cls.current_block_filter} ${
									el === "servStatus" ? cls.current_block_filter_top : ""
								}`}
							>
								{a}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
	setFilterOpen: PropTypes.func.isRequired,
};

export default EnhancedTableToolbar;
