import React, { useEffect, useRef } from "react";
import EquipmentAndServices from "./component/EquipmentAndServices/EquipmentAndServices";
import { useDispatch, useSelector } from "react-redux";
import services from "../../redux/actions/services";
import cls from "./equipment.module.scss";
import PrepareOrderPay from "../../components/PrepareOrderPay";
import movementInRef from "../../helpers/movementInRef";
// import EquipmentSelector from '../../redux/Selectors/Equipment';
import HelmetComponent from "../../components/HelmetComponent";
import { useTranslation } from "react-i18next";

const PreOrder = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		prepareOrderPay,
		preOrderParams,
		isRequestedPreOrder,
		isRequestedOrderPay,
		preOrder,
	} = useSelector((state) => state.services);

	const { role } = useSelector((state) => state.authentication.user);
	const role1 = role === 1;

	const refEquipmentAndServices = useRef();
	const refPrepareOrderPay = useRef();
	const resetTable = () => dispatch(services.resetOrder());

	let dataWrap = {};

	useEffect(() => {
		if (preOrder.length) {
			let newArrPreOrder = [];
			preOrder.forEach(({ items }) => {
				newArrPreOrder = [...newArrPreOrder, ...items];
			});

			dataWrap.orderData = newArrPreOrder;

			dispatch(services.prepareOrderPay(dataWrap, null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preOrder]);

	useEffect(() => {
		return () => {
			resetTable();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={cls.equipment_container_wrapper}>
			<HelmetComponent
				titlePage={t("Предзаказ")}
				description={t(
					"Здесь Вы можете приобрести новое или продлить старое оборудование"
				)}
				title={t("Предзаказ")}
			/>
			{preOrder.length && (
				<div ref={refEquipmentAndServices}>
					<EquipmentAndServices
						role={role}
						resetTable={resetTable}
						loading={isRequestedPreOrder}
						prepareOrderPay={(params) => {
							dataWrap.orderData = params;
							dispatch(
								services.prepareOrderPay(dataWrap, null, () =>
									movementInRef(refPrepareOrderPay)
								)
							);
						}}
					/>
				</div>
			)}

			{prepareOrderPay?.orderData?.length > 0 && role1 && (
				<div ref={refPrepareOrderPay}>
					<HelmetComponent
						titlePage={t("Новый заказ")}
						description={t(
							"Здесь Вы можете приобрести новое или продлить старое оборудование"
						)}
						title={t("Новый заказ")}
					/>
					<PrepareOrderPay
						loading={isRequestedOrderPay}
						params={prepareOrderPay.orderParams}
						rows={prepareOrderPay.orderData}
						errorExpDates={prepareOrderPay.orderErrorExpDates}
						warning={prepareOrderPay.orderWarningDates}
						error={prepareOrderPay.orderErrorDates}
						errorBalance={prepareOrderPay.orderErrorBalance}
						errorTerm={prepareOrderPay.orderErrorTerm}
						resetOrder={() => dispatch(services.resetOrder())}
						preOrderParams={preOrderParams}
						backStep={() => movementInRef(refEquipmentAndServices)}
						orderTerms={prepareOrderPay.orderTerms}
						orderTariffs={prepareOrderPay.orderTariffs}
						dispatchPrepareOrder={(params, callback) =>
							dispatch(services.prepareOrderPay(params, null, callback))
						}
						dispatchOrderPay={(params, callback) =>
							dispatch(services.orderPay(params, null, callback))
						}
					/>
				</div>
			)}
		</div>
	);
};

export default PreOrder;
