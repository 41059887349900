import types from './../Types'

const initialvalues = {
  isLoggedIn: false,
  isRequested: false,
  resetPassword: false,
  error: '',
  user: {},
  userOrgList: [],
};

const authentication = (state = initialvalues, action) => {
  switch (action.type) {
    case types.USER_REQUEST:
      return { ...state, isRequested: true, error: '' };
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,//time
        isRequested: false,
        user: action.user,
        resetPassword: action.resetPassword
      };
    case types.USER_ORGLIST_REQUEST_SUCCESS:
      return {
        ...state,
        userOrgList: action.userOrgList,
      };
    case types.USER_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.USER_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true,
      }
    case types.USER_IS_NOT_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: false,
        isRequested: false,
      }
    default:
      return state;
  }
}

export default authentication;
