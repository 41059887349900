import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
	TableContainer,
	Table,
	TableBody,
	Paper,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";

import cls from "../../../transactions.module.scss";

import EnhancedTableHead from "../../EnhancedTableHead";
import HandlerRowDesktopAndMobile from "../../../../../components/HandlerRowDesktopAndMobile";

import SortHelper from "../../../../../helpers/sortHelper";
import { useHandleRequestSort } from "../../../../../helpers/handleRequestSort";

import transactions from "../../../../../redux/actions/transactions";

const BatchTran = ({ row }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const headCells = [
		{
			id: "trnDt",
			numeric: false,
			disablePadding: true,
			align: "center",
			label: t("Дата"),
			bool: false,
			mobileLine: true,
		},
		{
			id: "chqN",
			numeric: true,
			disablePadding: false,
			align: "center",
			label: t("№ чека"),
			bool: false,
			mobileLine: true,
		},
		{
			id: "trnAmt",
			numeric: false,
			disablePadding: false,
			align: "center",
			label: t("Сумма"),
			bool: false,
			mobileLine: true,
		},
		{
			id: "trnType",
			numeric: false,
			disablePadding: false,
			align: "center",
			label: t("Операция"),
			bool: false,
			mobileLine: true,
		},
		{
			id: "pan",
			numeric: false,
			disablePadding: false,
			align: "center",
			label: t("Карта"),
			bool: false,
			mobileLine: false,
		},
		{
			id: "rrn",
			numeric: false,
			disablePadding: false,
			align: "center",
			label: t("RRN"),
			bool: false,
			mobileLine: true,
		},
		{
			id: "auth",
			numeric: false,
			disablePadding: false,
			align: "center",
			label: t("Код авторизации"),
			bool: false,
			mobileLine: true,
		},
	];

	const rows = useSelector((state) => state.transactions.batchTran);
	const rowId = row.id;

	const [{ order, orderBy }] = useHandleRequestSort({
		order: "asc",
		orderBy: "chqN",
	});

	useEffect(() => {
		dispatch(transactions.batchTran({ id: rowId }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, rowId]);

	return (
		<Paper className={cls.paper}>
			{rows.length > 0 ? (
				<TableContainer className={cls.container_wrapper}>
					<Table
						className={`${cls.table_container} ${cls.minimize_padding} ${cls.batch_tran_table}`}
						aria-labelledby='tableTitle'
						size='medium'
						aria-label='enhanced table'
					>
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							headCells={headCells}
							email
						/>
						<TableBody>
							{SortHelper(rows, order, orderBy).map((row, index) => {
								return (
									<Fragment key={index}>
										<HandlerRowDesktopAndMobile
											headCells={headCells}
											row={row}
											column
											index={index}
											minimize
											paddingCheck
											email
											handleClickEmail={() => {
												dispatch(
													transactions.emailBatchTran(
														{ id: row.id },
														(data) => {
															const response = data.data;

															if (response.resCode === 0)
																toast.success(response.resMsg);
															else toast.error(response.resMsg);
														}
													)
												);
											}}
										/>
									</Fragment>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Toolbar className={cls.toolbar}>
					<Typography
						className={cls.title}
						variant='body2'
						id='batchListTitle'
					>
						<span>{t("Нет операций для выгрузки")}</span>
					</Typography>
				</Toolbar>
			)}
		</Paper>
	);
};

export default BatchTran;
