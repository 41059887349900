import types from '../Types';

const myOrders = (callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.ORDERS_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/my/orders'
    }).then(
      (data) => {
        dispatch({ type: types.ORDERS_REQUEST_SUCCESS, orders: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.ORDERS_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

const ordersFree = (callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.ORDERS_REQUEST_FREE });
    return api({
      method: 'POST',
      url: '/api/cabinet/my/orders/free'
    }).then(
      (data) => {
        dispatch({ type: types.ORDERS_REQUEST_FREE_SUCCESS, ordersFree: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.ORDERS_REQUEST_FREE_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

const currentMyOrder = (current, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.CURRENT_ORDER_REQUEST });
    return api({
      method: 'POST',
      url: `/api/cabinet/my/order/${current}`
    }).then(
      (data) => {
        dispatch({ type: types.CURRENT_ORDER_REQUEST_SUCCESS, order: data.data });
        callback && callback(data);
      },
      ({ response }) => {
        dispatch({ type: types.CURRENT_ORDER_REQUEST_ERROR, error: response });
        callback && callback(response);
      },
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    myOrders,
    ordersFree,
    currentMyOrder
};
