import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AdjustIcon from '@material-ui/icons/Adjust';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const statusIconGenerator = (status, params, t) => {
	if (params === "doubleIcon") {
		switch (status) {
			case 'cancel':
				return <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap'}}><HighlightOffIcon style={{color: 'red', width: 20, marginRight: 5, height: 20 }} />  {t('Отменен')}</div>;
			case 'warn':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ErrorIcon style={{color: 'yellow', width: 20, marginRight: 5, height: 20 }} />  {t('Истекает')}</div>;
			case 'off':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CheckCircleOutlineIcon style={{color: 'grey', width: 20, marginRight: 5, height: 20 }} /> {t('Исполнен')}</div>;
			case 'pre':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><AdjustIcon style={{color: 'lightseagreen', width: 20, marginRight: 5, height: 20 }} /> {t('Ожидает')}</div>;
			case 'ok':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ScheduleIcon style={{color: 'green', width: 20, marginRight: 5, height: 20 }} /> {t('Действующий')}</div>;
			default:
				return;
		}
	} else if (params === "icon") {
		switch (status) {
			case 'new':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap' }}>
					<CheckCircleOutlineIcon style={{color: 'indianred', width: 30, marginRight: 5, height: 30 }} /></div>;
			case 'warn':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ErrorIcon style={{color: 'yellow', width: 30, marginRight: 5, height: 30 }} /></div>;
			case 'pre':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><AdjustIcon style={{color: 'lightseagreen', width: 30, marginRight: 5, height: 30 }} /></div>;
			case 'ok':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ScheduleIcon style={{color: 'green', width: 30, marginRight: 5, height: 30 }} /></div>;
			default:
				return;
		}
	} else {
		switch (status) {
			case 'new':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap' }}><CheckCircleOutlineIcon style={{color: 'indianred', width: 20, marginRight: 5, height: 20 }} /> {t('Не активно')}</div>;
			case 'warn':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ErrorIcon style={{color: 'yellow', width: 20, marginRight: 5, height: 20 }} /> {t('Истекает')}</div>;
			case 'pre':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><AdjustIcon style={{color: 'lightseagreen', width: 20, marginRight: 5, height: 20 }} /> {t('Ожидает')}</div>;
			case 'ok':
				return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ScheduleIcon style={{color: 'green', width: 20, marginRight: 5, height: 20 }} /> {t('Действующий')}</div>;
			default:
				return;
		}
	}
};

export default statusIconGenerator;