import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ru";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ButtonStyle from "../ButtonStyle";
import cls from "./dataPicker.module.scss";
import ru from "date-fns/locale/ru";
import { useTranslation } from "react-i18next";

const DataPickerComponent = ({
	selected,
	onChange,
	minDate,
	maxDate,
	startDate,
	endDate,
	month,
	active,
	...props
}) => {
	const {
		i18n: { language },
	} = useTranslation();
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
		return (
			<ButtonStyle
				innerRef={ref}
				endIcon={<DateRangeOutlinedIcon fontSize='small' />}
				forwardRef={ref}
				styleVariable={!active ? "outlined" : "default"}
				onClick={onClick}
			>
				{moment(new Date(value))
					.locale(language)
					.format(month ? "MMMM" : "DD.MM.YYYY", "ru")}
			</ButtonStyle>
		);
	});
	return (
		<div className={cls.data_picker_wrapper}>
			<ReactDatePicker
				startDate={startDate}
				endDate={endDate}
				selected={selected}
				onChange={onChange}
				customInput={<ExampleCustomInput />}
				minDate={minDate}
				maxDate={maxDate}
				locale={language === "ru" ? ru : ""}
				{...props}
			/>
		</div>
	);
};
DataPickerComponent.propTypes = {
	selected: PropTypes.instanceOf(Date),
	onChange: PropTypes.func,
};

DataPickerComponent.defaultProps = {
	selected: new Date(),
	minDate: new Date(),
	maxDate: null,
	showMonthYearPicker: false,
	month: false,
};
export default DataPickerComponent;
